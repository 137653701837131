import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { Reducer } from 'redux';
import { PersistPartial } from 'redux-persist/es/persistReducer';
import { put, takeLatest, call } from 'redux-saga/effects';

import { ActionsUnion, createAction } from '../../utils/action-helper';
import {
  getTariffs,
  getTariffById,
  editTariff,
  createPayment,
  createSubscription,
} from '../../crud/tariff.crud';
import { IServerResponse } from '../../interfaces/server';
import { ICreateSubscription, ITariff, ITariffEditProps } from '../../interfaces/tariff';
import { UserRoles } from '../../interfaces/user';
import { TAppActions } from '../rootDuck';

const FETCH_REQUEST = 'tariffs/FETCH_REQUEST';
const FETCH_SUCCESS = 'tariffs/FETCH_SUCCESS';
const FETCH_FAIL = 'tariffs/FETCH_FAIL';

const FETCH_BY_ID_REQUEST = 'tariffs/FETCH_BY_ID_REQUEST';
const FETCH_BY_ID_SUCCESS = 'tariffs/FETCH_BY_ID_SUCCESS';
const FETCH_BY_ID_FAIL = 'tariffs/FETCH_BY_ID_FAIL';

const CLEAR_EDIT = 'tariffs/CLEAR_EDIT';
const EDIT_REQUEST = 'tariffs/EDIT_REQUEST';
const EDIT_SUCCESS = 'tariffs/EDIT_SUCCESS';
const EDIT_FAIL = 'tariffs/EDIT_FAIL';

const PAY_INIT_CLEAR = 'tariffs/PAY_INIT_CLEAR';
const PAY_INIT_REQUEST = 'tariffs/PAY_INIT_REQUEST';
const PAY_INIT_SUCCESS = 'tariffs/PAY_INIT_SUCCESS';
const PAY_INIT_FAIL = 'tariffs/PAY_INIT_FAIL';

const PAY_SUBSCRIPTION_CLEAR = 'tariffs/PAY_SUBSCRIPTION_CLEAR';
const PAY_SUBSCRIPTION_REQUEST = 'tariffs/PAY_SUBSCRIPTION_REQUEST';
const PAY_SUBSCRIPTION_SUCCESS = 'tariffs/PAY_SUBSCRIPTION_SUCCESS';
const PAY_SUBSCRIPTION_FAIL = 'tariffs/PAY_SUBSCRIPTION_FAIL';

export type TariffEditFields =
  | 'price7days'
  | 'price30days'
  | 'price120days'
  | 'price365days'
  | 'displaying_other_contacts';

export interface IInitialState {
  tariffs: ITariff[];
  loading: boolean;
  success: boolean;
  error: string | null;

  payTariff: ITariff | undefined;
  byIdLoading: boolean;
  byIdSuccess: boolean;
  byIdError: string | null;

  editLoading: boolean;
  editSuccess: boolean;
  editError: string | null;

  delLoading: boolean;
  delSuccess: boolean;
  delError: string | null;

  payClientSecret: string;
  payInitLoading: boolean;
  payInitSuccess: boolean;
  payInitError: string | null;

  paySubscriptionLoading: boolean;
  paySubscriptionSuccess: boolean;
  paySubscriptionError: string | null;
}

const initialState: IInitialState = {
  tariffs: [],
  loading: false,
  success: false,
  error: null,

  payTariff: undefined,
  byIdLoading: false,
  byIdSuccess: false,
  byIdError: null,

  editLoading: false,
  editSuccess: false,
  editError: null,

  delLoading: false,
  delSuccess: false,
  delError: null,

  payClientSecret: '',
  payInitLoading: false,
  payInitSuccess: false,
  payInitError: null,

  paySubscriptionLoading: false,
  paySubscriptionSuccess: false,
  paySubscriptionError: null,
};

export const reducer: Reducer<IInitialState & PersistPartial, TAppActions> = persistReducer(
  { storage, key: 'tariffs', whitelist: ['user', 'authToken'] },
  (state = initialState, action) => {
    switch (action.type) {
      case FETCH_REQUEST: {
        return {
          ...state,
          tariffs: [],
          loading: true,
          success: false,
          error: null,
        };
      }

      case FETCH_SUCCESS: {
        return {
          ...state,
          total: action.payload.total,
          tariffs: action.payload.data,
          loading: false,
          success: true,
        };
      }

      case FETCH_FAIL: {
        return { ...state, loading: false, error: action.payload };
      }

      case FETCH_BY_ID_REQUEST: {
        return {
          ...state,
          tariff: undefined,
          byIdLoading: true,
          byIdSuccess: false,
          byIdError: null,
        };
      }

      case FETCH_BY_ID_SUCCESS: {
        return {
          ...state,
          tariff: action.payload.data,
          byIdLoading: false,
          byIdSuccess: true,
        };
      }

      case FETCH_BY_ID_FAIL: {
        return { ...state, byIdLoading: false, byIdError: action.payload };
      }

      case CLEAR_EDIT: {
        return {
          ...state,
          tariff: undefined,
          editLoading: false,
          editSuccess: false,
          editError: null,
          delError: null,
        };
      }

      case EDIT_REQUEST: {
        return { ...state, editLoading: true, editSuccess: false, editError: null };
      }

      case EDIT_SUCCESS: {
        return {
          ...state,
          editLoading: false,
          editSuccess: true,
          tariff: action.payload.data,
        };
      }

      case EDIT_FAIL: {
        return { ...state, editLoading: false, editError: action.payload };
      }

      case PAY_INIT_CLEAR: {
        return {
          ...state,
          payInitLoading: false,
          payInitSuccess: false,
          payInitError: null,
          payClientSecret: '',
        };
      }

      case PAY_INIT_REQUEST: {
        return {
          ...state,
          payInitLoading: true,
          payInitSuccess: false,
          payInitError: null,
          payClientSecret: '',
        };
      }

      case PAY_INIT_SUCCESS: {
        return {
          ...state,
          payInitLoading: false,
          payInitSuccess: true,
          payClientSecret: action.payload.data.client_secret,
        };
      }

      case PAY_INIT_FAIL: {
        return { ...state, payInitLoading: false, payInitError: action.payload };
      }

      case PAY_SUBSCRIPTION_CLEAR: {
        return {
          ...state,
          paySubscriptionLoading: false,
          paySubscriptionSuccess: false,
          paySubscriptionError: null,
        };
      }

      case PAY_SUBSCRIPTION_REQUEST: {
        return {
          ...state,
          paySubscriptionLoading: true,
          paySubscriptionSuccess: false,
          paySubscriptionError: null,
        };
      }

      case PAY_SUBSCRIPTION_SUCCESS: {
        return {
          ...state,
          paySubscriptionLoading: false,
          paySubscriptionSuccess: true,
        };
      }

      case PAY_SUBSCRIPTION_FAIL: {
        return {
          ...state,
          paySubscriptionLoading: false,
          paySubscriptionError: action.payload,
        };
      }

      default:
        return state;
    }
  }
);

export const actions = {
  fetchRequest: (payload: { role: UserRoles }) => createAction(FETCH_REQUEST, payload),
  fetchSuccess: (payload: IServerResponse<ITariff[]>) => createAction(FETCH_SUCCESS, payload),
  fetchFail: (payload: string) => createAction(FETCH_FAIL, payload),

  fetchByIdRequest: (payload: number) => createAction(FETCH_BY_ID_REQUEST, payload),
  fetchByIdSuccess: (payload: IServerResponse<ITariff>) =>
    createAction(FETCH_BY_ID_SUCCESS, payload),
  fetchByIdFail: (payload: string) => createAction(FETCH_BY_ID_FAIL, payload),

  clearEdit: () => createAction(CLEAR_EDIT),
  editRequest: (payload: { id: number; data: ITariffEditProps | any }) =>
    createAction(EDIT_REQUEST, payload),
  editSuccess: (payload: IServerResponse<ITariff>) => createAction(EDIT_SUCCESS, payload),
  editFail: (payload: string) => createAction(EDIT_FAIL, payload),

  payInitClear: () => createAction(PAY_INIT_CLEAR),
  payInitRequest: (payload: { tariff_id: number; amount: number }) =>
    createAction(PAY_INIT_REQUEST, payload),
  payInitSuccess: (payload: IServerResponse<{ client_secret: string }>) =>
    createAction(PAY_INIT_SUCCESS, payload),
  payInitFail: (payload: string) => createAction(PAY_INIT_FAIL, payload),

  paySubscriptionClear: () => createAction(PAY_SUBSCRIPTION_CLEAR),
  paySubscriptionRequest: (payload: ICreateSubscription) =>
    createAction(PAY_SUBSCRIPTION_REQUEST, payload),
  paySubscriptionSuccess: () => createAction(PAY_SUBSCRIPTION_SUCCESS),
  paySubscriptionFail: (payload: string) => createAction(PAY_SUBSCRIPTION_FAIL, payload),
};

export type TActions = ActionsUnion<typeof actions>;

function* fetchSaga({ payload }: { payload: { role: UserRoles } }) {
  try {
    const { data }: { data: IServerResponse<ITariff[]> } = yield call(() =>
      getTariffs(payload.role)
    );
    yield put(actions.fetchSuccess(data));
  } catch (e) {
    yield put(actions.fetchFail(e?.response?.data?.message || 'Network error'));
  }
}

function* fetchByIdSaga({ payload }: { payload: number }) {
  try {
    const { data }: { data: IServerResponse<ITariff> } = yield call(() =>
      getTariffById(payload)
    );
    yield put(actions.fetchByIdSuccess(data));
  } catch (e) {
    yield put(actions.fetchByIdFail(e?.response?.data?.message || 'Network error'));
  }
}

function* editSaga({ payload }: { payload: { id: number; data: ITariffEditProps } }) {
  try {
    const { data }: { data: IServerResponse<ITariff> } = yield call(() =>
      editTariff(payload.id, payload.data)
    );
    yield put(actions.editSuccess(data));
  } catch (e) {
    yield put(actions.editFail(e?.response?.data?.message || 'Network error'));
  }
}

function* createPaymentSaga({ payload }: { payload: { tariff_id: number; amount: number } }) {
  try {
    const { data }: { data: IServerResponse<{ client_secret: string }> } = yield call(() =>
      createPayment(payload.amount, payload.tariff_id)
    );

    yield put(actions.payInitSuccess(data));
  } catch (e) {
    yield put(actions.payInitFail(e?.response?.data?.message || 'Network error'));
  }
}

function* createSubscriptionSaga({ payload }: { payload: ICreateSubscription }) {
  try {
    yield call(() => createSubscription(payload));
    yield put(actions.paySubscriptionSuccess());
  } catch (e) {
    yield put(actions.paySubscriptionFail(e?.response?.data?.message || 'Network error'));
  }
}

export function* saga() {
  yield takeLatest<ReturnType<typeof actions.fetchRequest>>(FETCH_REQUEST, fetchSaga);
  yield takeLatest<ReturnType<typeof actions.fetchByIdRequest>>(
    FETCH_BY_ID_REQUEST,
    fetchByIdSaga
  );
  yield takeLatest<ReturnType<typeof actions.editRequest>>(EDIT_REQUEST, editSaga);
  yield takeLatest<ReturnType<typeof actions.payInitRequest>>(
    PAY_INIT_REQUEST,
    createPaymentSaga
  );
  yield takeLatest<ReturnType<typeof actions.paySubscriptionRequest>>(
    PAY_SUBSCRIPTION_REQUEST,
    createSubscriptionSaga
  );
}
