import { useEffect, useState } from 'react';
import { EditorState } from 'draft-js';
import { stateFromHTML } from 'draft-js-import-html';

type IUseEditorState = [EditorState, (state: EditorState) => void];

export const useEditorState = (text?: string): IUseEditorState => {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  useEffect(() => {
    if (text) {
      setEditorState(EditorState.createWithContent(stateFromHTML(text)));
    }
  }, [text]);

  return [editorState, setEditorState];
};
