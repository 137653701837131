import { useSnackbar } from 'notistack';
import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getSimilar } from '../../../../crud/product.crud';
import { getResponseMessage } from '../../../../utils/utils';
import { useFormatMessage } from '../../../../hooks';
import { IProduct } from '../../../../interfaces/product';

export const useGetSimilar: any = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [products, setProducts] = useState<IProduct[]>([]);
  const dispatch = useDispatch();
  const fm = useFormatMessage();

  const fetchSimilar = useCallback(
    async (id: number) => {
      setLoading(true);
      getSimilar(id)
        .then(res => {
          setProducts(res.data.data);
        })
        .catch(e => {
          enqueueSnackbar(`${fm('ERROR')}: ${getResponseMessage(e)}`, { variant: 'error' });
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [dispatch]
  );

  return [fetchSimilar, loading, products];
};
