import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { Reducer } from 'redux';
import { call, put, takeLatest } from 'redux-saga/effects';
import { PersistPartial } from 'redux-persist/es/persistReducer';
import { TAppActions } from '../rootDuck';

import { ActionsUnion, createAction } from '../../utils/action-helper';
import { IServerResponse } from '../../interfaces/server';
import {
  ICreatePublication,
  IEditPublication,
  IPublication,
} from '../../pages/home/blog/interfaces';
import {
  createPublication,
  deletePublication,
  editPublication,
  getPublication,
  createPublicationLogo,
  getPublicationsUrl,
} from '../../crud/blog.crud';

const CLEAR_FETCH_PUBLICATIONS = 'blog/CLEAR_FETCH_PUBLICATIONS';
const FETCH_PUBLICATIONS_REQUEST = 'blog/FETCH_PUBLICATIONS_REQUEST';
const FETCH_PUBLICATIONS_SUCCESS = 'blog/FETCH_PUBLICATIONS_SUCCESS';
const FETCH_PUBLICATIONS_FAIL = 'blog/FETCH_PUBLICATIONS_FAIL';

const CLEAR_CREATE_PUBLICATION = 'blog/CLEAR_CREATE_PUBLICATION';
const CREATE_PUBLICATION_REQUEST = 'blog/CREATE_PUBLICATION_REQUEST';
const CREATE_PUBLICATION_SUCCESS = 'blog/CREATE_PUBLICATION_SUCCESS';
const CREATE_PUBLICATION_FAIL = 'blog/CREATE_PUBLICATION_FAIL';

const CLEAR_EDIT_PUBLICATION = 'blog/CLEAR_EDIT_PUBLICATION';
const EDIT_PUBLICATION_REQUEST = 'blog/EDIT_PUBLICATION_REQUEST';
const EDIT_PUBLICATION_SUCCESS = 'blog/EDIT_PUBLICATION_SUCCESS';
const EDIT_PUBLICATION_FAIL = 'blog/EDIT_PUBLICATION_FAIL';

const CLEAR_DELETE_PUBLICATION = 'blog/CLEAR_DELETE_PUBLICATION';
const DELETE_PUBLICATION_REQUEST = 'blog/DELETE_PUBLICATION_REQUEST';
const DELETE_PUBLICATION_SUCCESS = 'blog/DELETE_PUBLICATION_SUCCESS';
const DELETE_PUBLICATION_FAIL = 'blog/DELETE_PUBLICATION_FAIL';

const CLEAR_FETCH_PUBLICATION = 'blog/CLEAR_FETCH_PUBLICATION';
const FETCH_PUBLICATION_REQUEST = 'blog/FETCH_PUBLICATION_REQUEST';
const FETCH_PUBLICATION_SUCCESS = 'blog/FETCH_PUBLICATION_SUCCESS';
const FETCH_PUBLICATION_FAIL = 'blog/FETCH_PUBLICATION_FAIL';

const CLEAR_UPLOAD_PHOTO = 'blog/CLEAR_UPLOAD_PHOTO';
const UPLOAD_PHOTO_REQUEST = 'blog/UPLOAD_PHOTO_REQUEST';
const UPLOAD_PHOTO_SUCCESS = 'blog/UPLOAD_PHOTO_SUCCESS';
const UPLOAD_PHOTO_FAIL = 'blog/UPLOAD_PHOTO_FAIL';

export interface IInitialState {
  page: number;
  per_page: number;
  total: number;

  publications: IPublication[];
  publication: IPublication | null;

  publicationsLoading: boolean;
  publicationsSuccess: boolean;
  publicationsError: string | null;

  createPublicationLoading: boolean;
  createPublicationSuccess: boolean;
  createPublicationError: string | null;

  editPublicationLoading: boolean;
  editPublicationSuccess: boolean;
  editPublicationError: string | null;

  deletePublicationLoading: boolean;
  deletePublicationSuccess: boolean;
  deletePublicationError: string | null;

  fetchPublicationLoading: boolean;
  fetchPublicationSuccess: boolean;
  fetchPublicationError: string | null;

  uploadPhotoLoading: boolean;
  uploadPhotoSuccess: boolean;
  uploadPhotoError: string | null;
}

const initialState: IInitialState = {
  page: 1,
  per_page: 20,
  total: 0,

  publications: [],
  publication: null,

  publicationsLoading: false,
  publicationsSuccess: false,
  publicationsError: null,

  createPublicationLoading: false,
  createPublicationSuccess: false,
  createPublicationError: null,

  editPublicationLoading: false,
  editPublicationSuccess: false,
  editPublicationError: null,

  deletePublicationLoading: false,
  deletePublicationSuccess: false,
  deletePublicationError: null,

  fetchPublicationLoading: false,
  fetchPublicationSuccess: false,
  fetchPublicationError: null,

  uploadPhotoLoading: false,
  uploadPhotoSuccess: false,
  uploadPhotoError: null,
};

export const reducer: Reducer<IInitialState & PersistPartial, TAppActions> = persistReducer(
  { storage, key: 'blog', whitelist: ['user', 'authToken'] },
  (state = initialState, action) => {
    switch (action.type) {
      // GET ALL PUBLICATIONS
      case CLEAR_FETCH_PUBLICATIONS: {
        return {
          ...state,
          page: 1,
          per_page: 20,
          total: 0,
          publications: [],

          publicationsLoading: false,
          publicationsSuccess: false,
          publicationsError: null,
        };
      }

      case FETCH_PUBLICATIONS_REQUEST: {
        return {
          ...state,
          publicationsLoading: true,
          publicationsSuccess: false,
          publicationsError: null,
        };
      }

      case FETCH_PUBLICATIONS_SUCCESS: {
        return {
          ...state,
          page: action.payload.page,
          per_page: action.payload.per_page,
          total: action.payload.total,

          publicationsLoading: false,
          publicationsSuccess: true,
          publications: action.payload.data,
        };
      }

      case FETCH_PUBLICATIONS_FAIL: {
        return { ...state, publicationsLoading: false, publicationsError: action.payload };
      }

      // CREATE PUBLICATION
      case CLEAR_CREATE_PUBLICATION: {
        return {
          ...state,
          createPublicationLoading: false,
          createPublicationSuccess: false,
          createPublicationError: null,
        };
      }

      case CREATE_PUBLICATION_REQUEST: {
        return {
          ...state,
          createPublicationLoading: true,
          createPublicationSuccess: false,
          createPublicationError: null,
        };
      }

      case CREATE_PUBLICATION_SUCCESS: {
        return {
          ...state,
          createPublicationLoading: false,
          createPublicationSuccess: true,
          createPublication: action.payload.data,
        };
      }

      case CREATE_PUBLICATION_FAIL: {
        return {
          ...state,
          createPublicationLoading: false,
          createPublicationError: action.payload,
        };
      }

      // EDIT PUBLICATION
      case CLEAR_EDIT_PUBLICATION: {
        return {
          ...state,
          editPublicationLoading: false,
          editPublicationSuccess: false,
          editPublicationError: null,
          uploadPhotoError: null,
          uploadPhotoSuccess: false,
        };
      }

      case EDIT_PUBLICATION_REQUEST: {
        return {
          ...state,
          editPublicationLoading: true,
          editPublicationSuccess: false,
          editPublicationError: null,
        };
      }

      case EDIT_PUBLICATION_SUCCESS: {
        return {
          ...state,
          publication: action.payload.data,
          editPublicationLoading: false,
          editPublicationSuccess: true,
          editPublication: action.payload.data,
        };
      }

      case EDIT_PUBLICATION_FAIL: {
        return {
          ...state,
          editPublicationLoading: false,
          editPublicationError: action.payload,
        };
      }

      // DELETE PUBLICATION
      case CLEAR_DELETE_PUBLICATION: {
        return {
          ...state,
          deletePublicationLoading: false,
          deletePublicationSuccess: false,
          deletePublicationError: null,
        };
      }

      case DELETE_PUBLICATION_REQUEST: {
        return {
          ...state,
          deletePublicationLoading: true,
          deletePublicationSuccess: false,
        };
      }

      case DELETE_PUBLICATION_SUCCESS: {
        return {
          ...state,
          deletePublicationLoading: false,
          deletePublicationSuccess: true,
        };
      }

      case DELETE_PUBLICATION_FAIL: {
        return {
          ...state,
          deletePublicationLoading: false,
          deletePublicationError: action.payload,
        };
      }

      // FETCH PUBLICATION
      case CLEAR_FETCH_PUBLICATION: {
        return {
          ...state,
          publication: null,
          fetchPublicationLoading: false,
          fetchPublicationSuccess: false,
          fetchPublicationError: null,
        };
      }

      case FETCH_PUBLICATION_REQUEST: {
        return {
          ...state,
          fetchPublicationLoading: true,
          fetchPublicationSuccess: false,
        };
      }

      case FETCH_PUBLICATION_SUCCESS: {
        return {
          ...state,
          publication: action.payload.data,
          fetchPublicationLoading: false,
          fetchPublicationSuccess: true,
        };
      }

      case FETCH_PUBLICATION_FAIL: {
        return {
          ...state,
          fetchPublicationLoading: false,
          fetchPublicationError: action.payload,
        };
      }

      case CLEAR_UPLOAD_PHOTO: {
        return {
          ...state,
          uploadPhotoLoading: false,
          uploadPhotoSuccess: false,
          uploadPhotoError: null,
        };
      }

      case UPLOAD_PHOTO_REQUEST: {
        return {
          ...state,
          uploadPhotoLoading: true,
          uploadPhotoSuccess: false,
        };
      }

      case UPLOAD_PHOTO_SUCCESS: {
        return {
          ...state,
          publication: action.payload.data,
          uploadPhotoLoading: false,
          uploadPhotoSuccess: true,
        };
      }

      case UPLOAD_PHOTO_FAIL: {
        return {
          ...state,
          uploadPhotoLoading: false,
          uploadPhotoError: action.payload,
        };
      }

      default:
        return state;
    }
  }
);

export const actions = {
  clearFetchPublications: () => createAction(CLEAR_FETCH_PUBLICATIONS),
  publicationsRequest: (payload: { page: number; perPage: number; my?: boolean }) =>
    createAction(FETCH_PUBLICATIONS_REQUEST, payload),
  publicationsSuccess: (payload: IServerResponse<IPublication[]>) =>
    createAction(FETCH_PUBLICATIONS_SUCCESS, payload),
  publicationsFail: (payload: string) => createAction(FETCH_PUBLICATIONS_FAIL, payload),

  clearCreatePublication: () => createAction(CLEAR_CREATE_PUBLICATION),
  createPublicationRequest: (payload: { data: any }) =>
    createAction(CREATE_PUBLICATION_REQUEST, payload),
  createPublicationSuccess: (payload: IServerResponse<IPublication>) =>
    createAction(CREATE_PUBLICATION_SUCCESS, payload),
  createPublicationFail: (payload: string) => createAction(CREATE_PUBLICATION_FAIL, payload),

  clearEditPublication: () => createAction(CLEAR_EDIT_PUBLICATION),
  editPublicationRequest: (payload: { id: number; data: IEditPublication }) =>
    createAction(EDIT_PUBLICATION_REQUEST, payload),
  editPublicationSuccess: (payload: IServerResponse<IPublication>) =>
    createAction(EDIT_PUBLICATION_SUCCESS, payload),
  editPublicationFail: (payload: string) => createAction(EDIT_PUBLICATION_FAIL, payload),

  clearDeletePublication: () => createAction(CLEAR_DELETE_PUBLICATION),
  deletePublicationRequest: (payload: { id: number }) =>
    createAction(DELETE_PUBLICATION_REQUEST, payload),
  deletePublicationSuccess: () => createAction(DELETE_PUBLICATION_SUCCESS),
  deletePublicationFail: (payload: string) => createAction(DELETE_PUBLICATION_FAIL, payload),

  clearFetchEditPublication: () => createAction(CLEAR_FETCH_PUBLICATION),
  fetchEditPublicationRequest: (payload: { id: number }) =>
    createAction(FETCH_PUBLICATION_REQUEST, payload),
  fetchEditPublicationSuccess: (payload: IServerResponse<IPublication>) =>
    createAction(FETCH_PUBLICATION_SUCCESS, payload),
  fetchEditPublicationFail: (payload: string) => createAction(FETCH_PUBLICATION_FAIL, payload),

  clearUploadPhoto: () => createAction(CLEAR_UPLOAD_PHOTO),
  uploadPhotoRequest: (payload: { id: number; data: FormData }) =>
    createAction(UPLOAD_PHOTO_REQUEST, payload),
  uploadPhotoSuccess: (payload: IServerResponse<IPublication>) =>
    createAction(UPLOAD_PHOTO_SUCCESS, payload),
  uploadPhotoFail: (payload: string) => createAction(UPLOAD_PHOTO_FAIL, payload),
};

export type TActions = ActionsUnion<typeof actions>;

function* fetchAllPublicationsSaga({
  payload,
}: {
  payload: { page: number; perPage: number; my?: boolean };
}) {
  try {
    const { data }: { data: IServerResponse<IPublication[]> } = yield call(() => {
      return getPublicationsUrl(payload);
    });
    yield put(actions.publicationsSuccess(data));
  } catch (e) {
    yield put(actions.publicationsFail(e?.response?.data?.message || 'Network error'));
  }
}

function* createPublicationSaga({ payload }: { payload: { data: ICreatePublication } }) {
  try {
    const { data }: { data: IServerResponse<IPublication> } = yield call(() =>
      createPublication(payload.data)
    );
    yield put(actions.createPublicationSuccess(data));
  } catch (e) {
    yield put(actions.createPublicationFail(e?.response?.data?.message || 'Network error'));
  }
}

function* editPublicationSaga({
  payload,
}: {
  payload: { id: number; data: IEditPublication };
}) {
  try {
    const { data }: { data: IServerResponse<IPublication> } = yield call(() =>
      editPublication(payload.id, payload.data)
    );
    yield put(actions.editPublicationSuccess(data));
  } catch (e) {
    yield put(actions.editPublicationFail(e?.response?.data?.message || 'Network error'));
  }
}

function* deletePublicationSaga({ payload }: { payload: { id: number } }) {
  try {
    yield call(() => deletePublication(payload.id));
    yield put(actions.deletePublicationSuccess());
  } catch (e) {
    yield put(actions.deletePublicationFail(e?.response?.data?.message || 'Network error'));
  }
}

function* fetchEditPublicationSaga({ payload }: { payload: { id: number } }) {
  try {
    const { data }: { data: IServerResponse<IPublication> } = yield call(() =>
      getPublication(payload.id)
    );
    yield put(actions.fetchEditPublicationSuccess(data));
  } catch (e) {
    yield put(actions.fetchEditPublicationFail(e?.response?.data?.message || 'Network error'));
  }
}

function* uploadPhotoSaga({ payload }: { payload: { id: number; data: FormData } }) {
  try {
    const { data }: { data: IServerResponse<IPublication> } = yield call(() =>
      createPublicationLogo(payload.id, payload.data)
    );
    yield put(actions.uploadPhotoSuccess(data));
  } catch (e) {
    yield put(actions.uploadPhotoFail(e?.response?.data?.message || 'Network error'));
  }
}

export function* saga() {
  yield takeLatest<ReturnType<typeof actions.publicationsRequest>>(
    FETCH_PUBLICATIONS_REQUEST,
    fetchAllPublicationsSaga
  );
  yield takeLatest<ReturnType<typeof actions.createPublicationRequest>>(
    CREATE_PUBLICATION_REQUEST,
    createPublicationSaga
  );
  yield takeLatest<ReturnType<typeof actions.editPublicationRequest>>(
    EDIT_PUBLICATION_REQUEST,
    editPublicationSaga
  );
  yield takeLatest<ReturnType<typeof actions.deletePublicationRequest>>(
    DELETE_PUBLICATION_REQUEST,
    deletePublicationSaga
  );
  yield takeLatest<ReturnType<typeof actions.fetchEditPublicationRequest>>(
    FETCH_PUBLICATION_REQUEST,
    fetchEditPublicationSaga
  );
  yield takeLatest<ReturnType<typeof actions.uploadPhotoRequest>>(
    UPLOAD_PHOTO_REQUEST,
    uploadPhotoSaga
  );
}
