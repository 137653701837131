import { useMemo } from 'react';
import { IUser, UserRoleType } from '../interfaces/user';

export const useDefineUserRole = (me: IUser | undefined, role: UserRoleType) => {
  return useMemo(() => {
    if (!me) return null;
    if (me.roles.includes(role)) {
      return true;
    }
    return false;
  }, [me, role]);
};
