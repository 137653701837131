import { Reducer } from 'redux';
import { call, put, takeLatest } from 'redux-saga/effects';

import { TAppActions } from '../rootDuck';
import { ActionsUnion, createAction } from '../../utils/action-helper';
import { ILocation } from '../../interfaces/locations';
import { getLocation, getLocationByCoord } from '../../crud/location.crud';
import { googleDataToEntities } from '../../utils/location';

const CLEAR = 'yaLocations/CLEAR';
const FETCH_REQUEST = 'yaLocations/FETCH_REQUEST';
const FETCH_SUCCESS = 'yaLocations/FETCH_SUCCESS';
const FETCH_FAIL = 'yaLocations/FETCH_FAIL';

const FETCH_BY_COORDS_REQUEST = 'yaLocations/FETCH_BY_COORDS_REQUEST';
const FETCH_BY_COORDS_SUCCESS = 'yaLocations/FETCH_BY_COORDS_SUCCESS';
const FETCH_BY_COORDS_FAIL = 'yaLocations/FETCH_BY_COORDS_FAIL';

export interface IInitialState {
  yaLocations: ILocation[] | undefined;
  loading: boolean;
  success: boolean;
  error: string | null;

  addressByCoords: {
    address: string;
    lat: number;
    lng: number;
  } | null;
  getLoacationByCoordsLoading: boolean;
  getLoacationByCoordsSuccess: boolean;
  getLoacationByCoordsError: string | null;
}

const initialState: IInitialState = {
  yaLocations: undefined,
  loading: false,
  success: false,
  error: null,

  addressByCoords: null,
  getLoacationByCoordsLoading: false,
  getLoacationByCoordsSuccess: false,
  getLoacationByCoordsError: null,
};

export const reducer: Reducer<IInitialState, TAppActions> = (state = initialState, action) => {
  switch (action.type) {
    case CLEAR: {
      return {
        ...state,
        yaLocations: undefined,
        loading: false,
        success: false,
        error: null,

        addressByCoords: null,
        getLoacationByCoordsLoading: false,
        getLoacationByCoordsSuccess: false,
        getLoacationByCoordsError: null,
      };
    }

    case FETCH_REQUEST: {
      return {
        ...state,
        yaLocations: undefined,
        loading: true,
        success: false,
        error: null,
      };
    }

    case FETCH_SUCCESS: {
      return {
        ...state,
        yaLocations: googleDataToEntities(action.payload) as any,
        loading: false,
        success: true,
      };
    }

    case FETCH_FAIL: {
      return { ...state, loading: false, error: action.payload };
    }

    case FETCH_BY_COORDS_REQUEST: {
      return {
        ...state,
        getLoacationByCoordsLoading: true,
        getLoacationByCoordsSuccess: false,
        getLoacationByCoordsError: null,
      };
    }

    case FETCH_BY_COORDS_SUCCESS: {
      return {
        ...state,
        addressByCoords: googleDataToEntities(action.payload)[0] as any,
        getLoacationByCoordsLoading: false,
        getLoacationByCoordsSuccess: true,
      };
    }

    case FETCH_BY_COORDS_FAIL: {
      return { ...state, loading: false, error: action.payload };
    }

    default:
      return state;
  }
};

export const actions = {
  clear: () => createAction(CLEAR),

  fetchRequest: (payload: { location: any; locale: string }) =>
    createAction(FETCH_REQUEST, payload),
  fetchSuccess: (payload: any) => createAction(FETCH_SUCCESS, payload),
  fetchFail: (payload: string) => createAction(FETCH_FAIL, payload),

  fetchByCoordsRequest: (payload: { lat: number; lng: number }) =>
    createAction(FETCH_BY_COORDS_REQUEST, payload),
  fetchByCoordsSuccess: (payload: any) => createAction(FETCH_BY_COORDS_SUCCESS, payload),
  fetchByCoordsFail: (payload: string) => createAction(FETCH_BY_COORDS_FAIL, payload),
};

export type TActions = ActionsUnion<typeof actions>;

function* fetchSaga({ payload }: { payload: { location: any; locale: string } }) {
  if (!payload) yield put(actions.clear());
  try {
    const { data }: { data: any } = yield call(() =>
      getLocation(payload.location, payload.locale)
    );
    yield put(actions.fetchSuccess(data));
  } catch (e) {
    yield put(actions.fetchFail(e?.response?.data?.message || 'Network error'));
  }
}

function* fetchByCoordsSaga({ payload }: { payload: { lat: number; lng: number } }) {
  try {
    const { data }: { data: any } = yield call(() => getLocationByCoord(payload));
    yield put(actions.fetchByCoordsSuccess(data));
  } catch (e) {
    yield put(actions.fetchByCoordsFail(e?.response?.data?.message || 'Network error'));
  }
}

export function* saga() {
  yield takeLatest<ReturnType<typeof actions.fetchRequest>>(FETCH_REQUEST, fetchSaga);
  yield takeLatest<ReturnType<typeof actions.fetchByCoordsRequest>>(
    FETCH_BY_COORDS_REQUEST,
    fetchByCoordsSaga
  );
}
