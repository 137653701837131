import React, { useState } from 'react';

type IUseTabsReturn = [number, (event: React.ChangeEvent<{}>, value: any) => void];

export const useTabs = (): IUseTabsReturn => {
  const [tabValue, setTabValue] = useState(0);

  const handleChangeTabValue = (event: React.ChangeEvent<{}>, value: any) => {
    setTabValue(value);
  };

  return [tabValue, handleChangeTabValue];
};
