import React from 'react';
import { useIntl } from 'react-intl';

interface IProps {
  changeAuthTab: any;
  email: string;
}

export const EmailSent: React.FC<IProps> = ({ changeAuthTab, email }) => {
  const intl = useIntl();
  return (
    <div className='kt-login__body'>
      <div className='kt-login__form'>
        <div className='kt-login__title'>
          <h3>{intl.formatMessage({ id: 'AUTH.EMAIL.TITLE' })}</h3>
        </div>
        <div className='kt-login__subtitle'>
          <h5>{intl.formatMessage({ id: 'AUTH.EMAIL.DESCRIPTION' }, { name: email })}</h5>
        </div>
        <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
          <div className='kt-login__actions' style={{ marginRight: 30 }}>
            <button
              onClick={() => changeAuthTab('CheckInSystem')}
              type='button'
              className='btn btn-secondary btn-elevate kt-login__btn-secondary'
            >
              {intl.formatMessage({ id: 'AUTH.GENERAL.BACK_BUTTON' })}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
