import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { Reducer } from 'redux';
import { PersistPartial } from 'redux-persist/es/persistReducer';
import { put, takeLatest, call } from 'redux-saga/effects';
import { TAppActions } from '../rootDuck';

import { ActionsUnion, createAction } from '../../utils/action-helper';
import { TChannel, TChatPostsForChannel, TMe } from '../../interfaces/chat';
import { ICompany } from '../../interfaces/companies';
import { IServerResponse } from '../../interfaces/server';
import { getChats } from '../../crud/chat.crud';

const CLEAR_CHATS = 'chat/CLEAR_CHATS';
const CLEAR_CHAT = 'chat/CLEAR_CHAT';
const SET_CHATS = 'chat/SET_CHATS';
const SET_CHAT = 'chat/SET_CHAT';
const SET_COMPANIES = 'chat/SET_COMPANIES';
const SET_ME = 'chat/SET_ME';
const SET_PAGINATION = 'chat/SET_PAGINATION';
const UPDATE_CHATS = 'chat/UPDATE_CHATS';

export interface IInitialState {
  chats: null | TChannel[];
  chat: null | TChatPostsForChannel;
  companies: null | ICompany[];
  me: TMe | null;
  page: number;
  perPage: number;
  total: number;
}

const initialState: IInitialState = {
  chats: null,
  chat: null,
  companies: null,
  me: null,
  page: 1,
  perPage: 30,
  total: 0,
};

export const reducer: Reducer<IInitialState & PersistPartial, TAppActions> = persistReducer(
  { storage, key: 'chat', whitelist: ['user', 'authToken'] },
  (state = initialState, action) => {
    switch (action.type) {
      case CLEAR_CHATS: {
        return { ...state, chats: null, companies: null };
      }

      case CLEAR_CHAT: {
        return { ...state, chat: null };
      }

      case SET_CHATS: {
        return { ...state, chats: action.payload };
      }

      case SET_COMPANIES: {
        return { ...state, companies: action.payload };
      }

      case SET_CHAT: {
        return { ...state, chat: action.payload };
      }

      case SET_ME: {
        return { ...state, me: action.payload };
      }

      case SET_PAGINATION: {
        return {
          ...state,
          page: action.payload.page,
          perPage: action.payload.perPage,
          total: action.payload.total,
        };
      }

      default:
        return state;
    }
  }
);

export const actions = {
  clearChats: () => createAction(CLEAR_CHATS),
  clearChat: () => createAction(CLEAR_CHAT),
  setChats: (payload: TChannel[]) => createAction(SET_CHATS, payload),
  setChat: (payload: TChatPostsForChannel) => createAction(SET_CHAT, payload),
  setCompanies: (payload: ICompany[]) => createAction(SET_COMPANIES, payload),
  setMe: (payload: TMe) => createAction(SET_ME, payload),
  setPagination: (payload: { page: number; perPage: number; total: number }) =>
    createAction(SET_PAGINATION, payload),
  updateChats: (payload: { page: number; perPage: number }) =>
    createAction(UPDATE_CHATS, payload),
};

export type TActions = ActionsUnion<typeof actions>;

function* fetchSaga({ payload }: { payload: { page: number; perPage: number } }) {
  try {
    const { data }: { data: IServerResponse<TChannel[]> } = yield call(() =>
      getChats(payload.page, payload.perPage)
    );
    yield put(actions.setChats(data.data));
  } catch (e) {
    // console.log(e);
  }
}

export function* saga() {
  yield takeLatest<ReturnType<typeof actions.updateChats>>(UPDATE_CHATS, fetchSaga);
}
