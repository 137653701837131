import { useFormik } from 'formik';
import { useIntl } from 'react-intl';
import * as Yup from 'yup';

export const useFormikEnterPassword = (email: string, makeRequest: any) => {
  const intl = useIntl();

  return useFormik({
    initialValues: { password: '' },
    validationSchema: Yup.object().shape({
      password: Yup.string().required(
        intl.formatMessage({ id: 'AUTH.VALIDATION.REQUIRED_FIELD' })
      ),
    }),
    onSubmit: submitValues => {
      makeRequest(email, submitValues.password);
    },
  });
};
