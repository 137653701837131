import React from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  Typography,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ClearIcon from '@material-ui/icons/Clear';

import { useStyles } from './useStyles';
import { TUserFilter } from './types';

interface IProps {
  type: keyof TUserFilter;
  name: string;
  filter: TUserFilter;
  userAttrStored: any[];
  handleChange: any;
  clear: any;
}

export const FilterAccordion: React.FC<IProps> = ({
  type,
  name,
  filter,
  handleChange,
  clear,
  userAttrStored,
}) => {
  const classes = useStyles();
  return (
    <Accordion className={classes.accordion}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon className={classes.icon} fontSize='small' />}
        aria-controls='panel1a-content'
        id='panel1a-header'
        classes={{ content: classes.accordionContent }}
      >
        <Typography className={classes.heading}>{name}</Typography>
        {filter[type].length > 0 && (
          <IconButton
            color='secondary'
            size='small'
            style={{ color: '#5c5e81' }}
            onClick={e => {
              e.stopPropagation();
              clear(type);
            }}
          >
            <ClearIcon fontSize='small' />
          </IconButton>
        )}
      </AccordionSummary>
      <AccordionDetails classes={{ root: classes.AccordionDetailsRoot }}>
        <FormControl component='fieldset'>
          <FormGroup>
            {userAttrStored.map((item: string) => (
              <FormControlLabel
                key={item}
                control={
                  <Checkbox
                    className={classes.checkbox}
                    checked={filter[type].includes(item) || false}
                    onChange={e => handleChange(type, e)}
                    name={item}
                    color='primary'
                    size='small'
                  />
                }
                label={item}
                classes={{ label: classes.checkboxLabel, root: classes.FormControlLabelRoot }}
              />
            ))}
          </FormGroup>
        </FormControl>
      </AccordionDetails>
    </Accordion>
  );
};
