import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  formControl: {
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  label: {
    color: '#fff',
    marginBottom: theme.spacing(2),
  },
  checkboxLabel: {
    color: '#fff',
    fontSize: '0.9375rem',
    fontWeight: 400,
    lineHeight: '17px',
  },
  checkbox: {
    color: '#fff',
  },
  divider: {
    background: theme.palette.divider,
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: 400,
    color: '#fff',
    backgroundColor: 'transparent',
  },
  accordion: {
    width: '100%',
    backgroundColor: 'transparent',
  },
  icon: {
    color: '#fff',
  },
  accordionContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  FormControlLabelRoot: {
    marginRight: 0,
    width: 195,
    marginBottom: 0,
  },
  AccordionDetailsRoot: {
    paddingTop: 0,
  },
}));
