import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { Reducer } from 'redux';
import { call, put, takeLatest } from 'redux-saga/effects';
import { PersistPartial } from 'redux-persist/es/persistReducer';
import { TAppActions } from '../rootDuck';

import { ActionsUnion, createAction } from '../../utils/action-helper';
import { IServerResponse } from '../../interfaces/server';
import { IMessage, IMessageCreate } from '../../interfaces/order';
import { createMessage, getMessages } from '../../crud/message.crud';

const CLEAR_FETCH_MESSAGES = 'messages/CLEAR_FETCH_MESSAGES';
const FETCH_MESSAGES_REQUEST = 'messages/FETCH_MESSAGES_REQUEST';
const FETCH_MESSAGES_SUCCESS = 'messages/FETCH_MESSAGES_SUCCESS';
const FETCH_MESSAGES_FAIL = 'messages/FETCH_MESSAGES_FAIL';

const CLEAR_CREATE_MESSAGE = 'messages/CLEAR_CREATE_MESSAGE';
const CREATE_MESSAGE_REQUEST = 'messages/CREATE_MESSAGE_REQUEST';
const CREATE_MESSAGE_SUCCESS = 'messages/CREATE_MESSAGE_SUCCESS';
const CREATE_MESSAGE_FAIL = 'messages/CREATE_MESSAGE_FAIL';

const CLEAR = 'messages/CLEAR';

export interface IInitialState {
  page: number;
  per_page: number;
  total: number;

  messages: IMessage[];
  message: IMessage | null;

  messagesLoading: boolean;
  messagesSuccess: boolean;
  messagesError: string | null;

  createMessageLoading: boolean;
  createMessageSuccess: boolean;
  createMessageError: string | null;
}

const initialState: IInitialState = {
  page: 1,
  per_page: 20,
  total: 0,

  messages: [],
  message: null,

  messagesLoading: false,
  messagesSuccess: false,
  messagesError: null,

  createMessageLoading: false,
  createMessageSuccess: false,
  createMessageError: null,
};

export const reducer: Reducer<IInitialState & PersistPartial, TAppActions> = persistReducer(
  { storage, key: 'messages', whitelist: ['user', 'authToken'] },
  (state = initialState, action) => {
    switch (action.type) {
      case CLEAR_FETCH_MESSAGES: {
        return {
          ...state,
          page: 1,
          per_page: 20,
          total: 0,
          messages: [],

          messagesLoading: false,
          messagesSuccess: false,
          messagesError: null,
        };
      }

      case FETCH_MESSAGES_REQUEST: {
        return {
          ...state,
          messagesLoading: true,
          messagesSuccess: false,
          messagesError: null,
        };
      }

      case FETCH_MESSAGES_SUCCESS: {
        return {
          ...state,
          page: action.payload.page,
          per_page: action.payload.per_page,
          total: action.payload.total,

          messagesLoading: false,
          messagesSuccess: true,
          messages: action.payload.data.reverse(),
        };
      }

      case FETCH_MESSAGES_FAIL: {
        return { ...state, messagesLoading: false, messagesError: action.payload };
      }

      case CLEAR_CREATE_MESSAGE: {
        return {
          ...state,
          createMessageLoading: false,
          createMessageSuccess: false,
          createMessageError: null,
        };
      }

      case CREATE_MESSAGE_REQUEST: {
        return {
          ...state,
          createMessageLoading: true,
          createMessageSuccess: false,
          createMessageError: null,
        };
      }

      case CREATE_MESSAGE_SUCCESS: {
        return {
          ...state,
          createMessageLoading: false,
          createMessageSuccess: true,
          createMessage: action.payload.data,
          messages: action.payload.data.reverse(),
        };
      }

      case CREATE_MESSAGE_FAIL: {
        return {
          ...state,
          createMessageLoading: false,
          createMessageError: action.payload,
        };
      }

      case CLEAR: {
        return initialState;
      }

      default:
        return state;
    }
  }
);

export const actions = {
  clearFetchMessages: () => createAction(CLEAR_FETCH_MESSAGES),
  messagesRequest: (payload: { page: number; perPage: number; order_id: number }) =>
    createAction(FETCH_MESSAGES_REQUEST, payload),
  messagesSuccess: (payload: IServerResponse<IMessage[]>) =>
    createAction(FETCH_MESSAGES_SUCCESS, payload),
  messagesFail: (payload: string) => createAction(FETCH_MESSAGES_FAIL, payload),

  clearCreateMessage: () => createAction(CLEAR_CREATE_MESSAGE),
  createMessageRequest: (payload: { data: IMessageCreate }) =>
    createAction(CREATE_MESSAGE_REQUEST, payload),
  createMessageSuccess: (payload: IServerResponse<IMessage[]>) =>
    createAction(CREATE_MESSAGE_SUCCESS, payload),
  createMessageFail: (payload: string) => createAction(CREATE_MESSAGE_FAIL, payload),

  clear: () => createAction(CLEAR),
};

export type TActions = ActionsUnion<typeof actions>;

function* fetchMessagesSaga({
  payload,
}: {
  payload: { page: number; perPage: number; order_id: number };
}) {
  try {
    const { data }: { data: IServerResponse<IMessage[]> } = yield call(() => {
      return getMessages(payload);
    });
    yield put(actions.messagesSuccess(data));
  } catch (e) {
    yield put(actions.messagesFail(e?.response?.data?.message || 'Network error'));
  }
}

function* createMessageSaga({ payload }: { payload: { data: IMessageCreate } }) {
  try {
    const { data }: { data: IServerResponse<IMessage[]> } = yield call(() =>
      createMessage(payload.data)
    );
    yield put(actions.createMessageSuccess(data));
  } catch (e) {
    yield put(actions.createMessageFail(e?.response?.data?.message || 'Network error'));
  }
}

export function* saga() {
  yield takeLatest<ReturnType<typeof actions.messagesRequest>>(
    FETCH_MESSAGES_REQUEST,
    fetchMessagesSaga
  );
  yield takeLatest<ReturnType<typeof actions.createMessageRequest>>(
    CREATE_MESSAGE_REQUEST,
    createMessageSaga
  );
}
