import * as Sentry from '@sentry/browser';

const SENTRY_KEY = 'cacec0c080e843e2a6393a91d142eb7a';
const SENTRY_APP = '5182692';

const configSentry = () => {
  if (process.env.NODE_ENV !== 'development') {
    Sentry.init({ dsn: `https://${SENTRY_KEY}@sentry.io/${SENTRY_APP}` });
  }
};

export default configSentry;
