import { makeStyles, createStyles } from '@material-ui/core';

export const useStylesProducView = makeStyles(theme =>
  createStyles({
    editor: {
      boxShadow: 'initial !important',
      padding: '0 !important',
    },
    container: {
      marginTop: '0',
      padding: '0',
    },

    card: {
      padding: '10px',
      // maxWidth: '1440px',
      backgroundColor: 'transparent',
      [theme.breakpoints.up('md')]: {
        flexDirection: 'row',
      },
      margin: '0 auto',
    },
    leftCol: {
      pointerEvents: 'auto',
      display: 'flex',
      flexDirection: 'column',
      width: '30%',
      marginRight: 30,
      minWidth: 350,
      // eslint-disable-next-line no-useless-computed-key
      ['@media (max-width:1000px)']: {
        maxWidth: 330,
        width: '100%',
        minWidth: 300,
      },
    },
    rightCol: {
      width: '70%',
      display: 'flex',
      flexDirection: 'column',
    },
    header: {
      width: '100%',
      maxWidth: '1440px',
      margin: '0 auto',
    },
    parameterValue: {
      display: 'flex',
      width: '100%',
      justifyContent: 'space-between',
      paddingRight: 100,
    },
    parameterText1: {
      color: '#000',
      fontSize: 15,
      fontWeight: 300,
      margin: 0,
    },
    parameterText2: {
      color: '#757575',
      fontSize: 11,
      fontWeight: 'normal',
      margin: 0,
    },
    icons: {
      display: 'flex',
      gap: 20,
    },
    parameter: {
      display: 'flex',
      gap: 20,
    },
    map: {
      width: '100%',
      height: 400,
      // objectFit: 'cover',
      position: 'relative',
      top: -50,
      zIndex: -2,
    },
    mapBlock: {
      width: '100%',
      height: 350,
      position: 'relative',
      top: 0,
    },
    name: {
      fontSize: 38,
      color: '#000',
      fontWeight: 300,
      textAlign: 'center',
      [theme.breakpoints.down('md')]: {
        fontSize: 28,
      },
    },
    infoBody: {
      marginTop: 20,
      display: 'flex',
      width: '100%',
      gap: 50,
    },
    infoText: {
      fontSize: 15,
      fontWeight: 300,
      color: '#000',
      margin: 0,
    },
    timeText1: {
      color: '#000',
      fontSize: 15,
      fontWeight: 300,
      margin: 0,
    },
    timeText2: {
      color: '#757575',
      fontSize: 11,
      fontWeight: 'normal',
      margin: 0,
    },
    time: {
      width: '100%',
      display: 'flex',
      flexWrap: 'wrap',
      gap: 8,
      marginTop: 20,
    },
    infoText2: {
      fontSize: 11,
      fontWeight: 'normal',
      color: '#757575',
      margin: 0,
    },
    infoBlockText: {
      display: 'flex',
      flexDirection: 'column',
      lineHeight: 1.2,
    },
    infoBodyBlock: {
      display: 'grid',
      gridTemplateColumns: 'repeat(2, 200px)',
      gridColumnGap: '40px', // Отступ для столбцов
      gridRowGap: '15px',
    },
    infoBodyBlockFlex: {
      display: 'flex',
      flexDirection: 'column',
      gap: 15,
    },
    newPriceTitle: {
      fontSize: 17,
      fontWeight: 'normal',
      color: '#000000',
      textAlign: 'center',
      textDecoration: 'line-through',
      margin: 0
    },
    priceTitle: {
      fontSize: 27,
      fontWeight: 'normal',
      color: '#ad1d11',
      textAlign: 'center',
      marginBottom: 0,
    },
    body: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      maxWidth: '1440px',
      margin: '0 auto',
      // alignItems: 'flex-end'
    },
    info: {
      width: '100%',
      pointerEvents: 'auto',
    },
    infoFlex: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      // marginBottom: 380,
    },
    price: {
      pointerEvents: 'auto',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '25%',
    },
    priceBody: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: '100%',
      // height: '180px',
      backgroundColor: '#fff',
    },
    addFav: {
      display: 'grid',
      gridTemplateColumns: 'repeat(3, 1fr)',
      alignItems: 'center',
      marginTop: 15,
    },
    favBtn: {
      backgroundColor: 'rgba(68, 24, 97, 0.65)',
      border: 'none',
      height: 50,
      width: 50,
      fontSize: 24,
      color: '#fff',

      transition: 'all .3s ease-out',
      '&:hover': {
        backgroundColor: '#441861',
      },
    },
    favText: {
      fontSize: 24,
      height: 50,
      width: 50,
      fontWeight: 'bold',
      color: '#441861',
      margin: 0,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    addBtn: {
      // width: 150,
      width: "max-content",
      minWidth:150,
      height: 50,
      border: 'none',
      color: '#fff',
      padding: '15px 25px',
      backgroundColor: '#441861',
      fontSize: 20,
      fontWeight: 'bold',
      marginTop: 25,
      '&:hover': {
        backgroundColor: 'rgba(68, 24, 97, 0.65)',
      },
    },
    description: {
      // position: 'absolute',
      width: '70%',
      backgroundColor: '#216214',
      padding: 25,
      display: 'flex',
      justifyContent: 'space-around',
      flexDirection: 'column',
      gap: 20,
      minHeight: 320,
      marginBottom: 30,
      marginTop: 20,
      // top: 275
      [theme.breakpoints.down('lg')]: {
        width: '100%',
      },
    },
    descriptionText: {
      fontSize: 15,
      fontWeight: 'normal',
      margin: 0,
      color: '#fff',
      lineHeight: 1.8,
      width: 'calc(100% - 61px)',
      overflowWrap: 'break-word',
    },
    descriptionBlock: {
      display: 'flex',
      gap: 20,
    },
    reviewContainer: {
      display: 'none',
      [theme.breakpoints.up('md')]: {
        display: 'block',
      },
    },
    companyBlock: {
      position: 'relative',
      // width: 'flex',
      backgroundColor: '#e9efe8',
      padding: 25,
      display: 'flex',
      flexDirection: 'column',
      gap: 15,
    },
    company: {
      // width: 'calc(30% - 30px)',
      position: 'absolute',
      left: '35px',
      zIndex: 5,
    },
    companyText1: {
      fontSize: 15,
      fontWeight: 300,
      color: '#000',
      margin: 0,
    },
    companyText2: {
      fontSize: 11,
      fontWeight: 'normal',
      color: '#757575',
      margin: 0,
    },
    infoCompany: {
      marginTop: 30,
      display: 'flex',
      width: '100%',
      justifyContent: 'space-between',
      // gap: 50,
    },
    degree: {
      // width: '70%',
      display: 'flex',
      alignItems: 'center',
      pointerEvents: 'auto',
    },
    degreeText: {
      margin: 0,
      fontSize: 17,
      fontWeight: 300,
      color: '#000',
      display: '-webkit-box',
      WebkitBoxOrient: 'vertical',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    degreeBlock: {
      width: '70%',
      marginBottom: 30,
      [theme.breakpoints.down('lg')]: {
        width: '100%',
      },
    },
    searchOnMap: {
      width: '100%',
      marginTop: 24,
      height: 130,
      cursor: 'pointer',
    },
    whatsAppButton: {
      maxWidth: '250px',
      height: '36px',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: 4,
      backgroundColor: '#25d366',
      fontSize: 11,
      fontWeight: 'bold',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 'normal',
      letterSpacing: 'normal',
      textAlign: 'center',
      color: '#fff',
      display: 'inline-block',
      marginTop: '10px',
      '&:hover': {
        color: '#fff',
      },
    },
    isLoading: {
      paddingRight: '3.5rem!important',
    },
    listItem: {
      marginBottom: 14,
    },
    listValue: {
      fontSize: 13,
      fontWeight: 600,
      color: '#000',
      margin: 0,
      whiteSpace: 'break-spaces',
      textTransform: 'uppercase',
    },
    listKey: {
      margin: 0,
      fontSize: 13,
      color: '#757575',
      fontWeight: 'normal',
    },
    companyCard: {
      // padding: '25px 30px',
      borderRadius: '4px',
    },
    parameterName: {
      color: '#757575',
      fontSize: 13,
      fontWeight: 400,
      margin: 0,
      paddingTop: 0,
      marginTop: -5,
    },
    imgPng: {
      width: 38,
      height: 38,
      position: 'absolute',
      left: 0,
      top: 0,
    },
    containerProduct: {
      width: '100%',
      display: 'flex',
      position: 'relative',
      justifyContent: 'center',
      alignItems: 'flex-end',
      zIndex: 100,
      pointerEvents: 'none',
    },
    imgBackground: {
      width: '100%',
      position: 'absolute',
      objectFit: 'cover',
      zIndex: -100,
      height: 280,
    },
    containerProductInfo: {
      minHeight: 250,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-end',
    },
    containerRate: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      width: 45,
      height: 45,
    },
    rating: {
      fontSize: 15,
      fontWeight: 400,
      color: '#000000',
      zIndex: 100,
    },
    containerAddProduct: {
      display: 'flex',
      gap: 10,
      alignItems: 'center',
      fontWeight: 'bold',
      fontSize: 20,
    },
    containerImgWine: {
      position: 'relative',
      width: 36,
      height: 36,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    productParam: {
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      fontSize: 10,
      fontWeight: 'bold',
      zIndex: 1000,
      padding: '0 3px',
      color: '#fff',
    },
    containerParamCompany: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      alignItems: 'center',
      maxWidth: '1440px',
      margin: '0 auto',
      marginTop: 0,
    },
    element: {
      height: '100%',
    },
    wrapperProductInfo: {
      position: 'relative',
    },
    containerParams: {
      width: '70%',
    },
    rateIcon: {
      position: 'absolute',
    },
    containerParamsTime: {
      display: 'flex',
      width: '100%',
    },
  })
);
