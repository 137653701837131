import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { Reducer } from 'redux';
import { PersistPartial } from 'redux-persist/es/persistReducer';
import { put, takeLatest, call } from 'redux-saga/effects';

import { TAppActions } from '../rootDuck';

import { ActionsUnion, createAction } from '../../utils/action-helper';
import { IServerResponse } from '../../interfaces/server';
import {
  getPromocodes,
  createPromocode,
  editPromocode,
  getPromocode,
  activatePromocode,
} from '../../crud/promocodes.crud';
import {
  IPromocode,
  IPromocodeEditProps,
  IPromocodeAddProps,
  IFilteredData,
} from '../../interfaces/promocodes';
import { getResponseMessage } from '../../utils/utils';

const CLEAR_FETCH = 'promocodes/CLEAR_FETCH';
const FETCH_REQUEST = 'promocodes/FETCH_REQUEST';
const FETCH_SUCCESS = 'promocodes/FETCH_SUCCESS';
const FETCH_FAIL = 'promocodes/FETCH_FAIL';
const CLEAR_PROMOCODES = 'promocodes/CLEAR_PROMOCODES';

const CLEAR_FETCH_BY_ID = 'promocodes/CLEAR_FETCH_BY_ID';
const FETCH_BY_ID_REQUEST = 'promocodes/FETCH_BY_ID_REQUEST';
const FETCH_BY_ID_SUCCESS = 'promocodes/FETCH_BY_ID_SUCCESS';
const FETCH_BY_ID_FAIL = 'promocodes/FETCH_BY_ID_FAIL';

const CLEAR_SEARCH = 'promocodes/CLEAR_SEARCH';
const SEARCH_REQUEST = 'promocodes/SEARCH_REQUEST';
const SEARCH_SUCCESS = 'promocodes/SEARCH_SUCCESS';
const SEARCH_FAIL = 'promocodes/SEARCH_FAIL';

const CLEAR_EDIT = 'promocodes/CLEAR_EDIT';
const ADD_REQUEST = 'promocodes/ADD_REQUEST';
const ACTIVATE_REQUEST = 'promocodes/ACTIVATE_REQUEST';
const EDIT_REQUEST = 'promocodes/EDIT_REQUEST';
const EDIT_SUCCESS = 'promocodes/EDIT_SUCCESS';
const EDIT_FAIL = 'promocodes/EDIT_FAIL';

const CLEAR_DEL_PROMOCODE = 'promocodes/CLEAR_DEL_PROMOCODE';
const DEL_PROMOCODE_REQUEST = 'promocodes/DEL_PROMOCODE_REQUEST';
const DEL_PROMOCODE_SUCCESS = 'promocodes/DEL_PROMOCODE_SUCCESS';
const DEL_PROMOCODE_FAIL = 'promocodes/DEL_PROMOCODE_FAIL';

export interface IInitialState {
  page: number;
  per_page: number;
  total: number;
  promocodes: IPromocode[];
  loading: boolean;
  success: boolean;
  error: string | null;

  promocode: IPromocode | null;
  byIdLoading: boolean;
  byIdSuccess: boolean;
  byIdError: string | null;

  by_user: boolean;

  searchLoading: boolean;
  searchSuccess: boolean;
  searchError: string | null;

  editLoading: boolean;
  editSuccess: boolean;
  editError: string | null;

  delPromocodeLoading: boolean;
  delPromocodeSuccess: boolean;
  delPromocodeError: string | null;
}

const defaultPaginatorProps = { page: 1, per_page: 20, total: 0 };

const initialState: IInitialState = {
  ...defaultPaginatorProps,
  promocodes: [],
  loading: false,
  success: false,
  error: null,

  promocode: null,
  byIdLoading: false,
  byIdSuccess: false,
  byIdError: null,

  by_user: false,

  searchLoading: false,
  searchSuccess: false,
  searchError: null,

  editLoading: false,
  editSuccess: false,
  editError: null,

  delPromocodeLoading: false,
  delPromocodeSuccess: false,
  delPromocodeError: null,
};

export const reducer: Reducer<IInitialState & PersistPartial, TAppActions> = persistReducer(
  { storage, key: 'promocodes', whitelist: ['promocodes', 'authToken'] },
  (state = initialState, action) => {
    switch (action.type) {
      case CLEAR_FETCH: {
        return { ...state, loading: false, error: null, success: false };
      }

      case CLEAR_PROMOCODES: {
        return { ...state, ...defaultPaginatorProps, promocodes: [] };
      }

      case FETCH_REQUEST: {
        return {
          ...state,
          ...defaultPaginatorProps,
          promocodes: [],
          loading: true,
          error: null,
          success: false,
        };
      }

      case FETCH_SUCCESS: {
        return {
          ...state,
          page: action.payload.page,
          per_page: action.payload.per_page,
          total: action.payload.total,
          promocodes: action.payload.data,
          loading: false,
          success: true,
        };
      }

      case FETCH_FAIL: {
        return { ...state, loading: false, error: action.payload };
      }

      case CLEAR_SEARCH: {
        return {
          ...state,
          promocodes: [],
          searchLoading: false,
          searchError: null,
          searchSuccess: false,
        };
      }

      case SEARCH_REQUEST: {
        return {
          ...state,
          promocodes: [],
          searchLoading: true,
          searchError: null,
          searchSuccess: false,
        };
      }

      case SEARCH_SUCCESS: {
        return {
          ...state,
          page: action.payload.page,
          per_page: action.payload.per_page,
          total: action.payload.total,
          promocodes: action.payload.data,
          searchLoading: false,
          searchSuccess: true,
        };
      }

      case SEARCH_FAIL: {
        return { ...state, searchLoading: false, searchError: action.payload };
      }

      case CLEAR_FETCH_BY_ID: {
        return {
          ...state,
          promocode: null,
          byIdLoading: false,
          byIdError: null,
          byIdSuccess: false,
        };
      }

      case FETCH_BY_ID_REQUEST: {
        return {
          ...state,
          promocode: null,
          byIdLoading: true,
          byIdError: null,
          byIdSuccess: false,
        };
      }

      case FETCH_BY_ID_SUCCESS: {
        return {
          ...state,
          promocode: action.payload.data,
          byIdLoading: false,
          byIdSuccess: true,
        };
      }

      case FETCH_BY_ID_FAIL: {
        return { ...state, byIdLoading: false, byIdError: action.payload };
      }

      case CLEAR_EDIT: {
        return {
          ...state,
          editLoading: false,
          editError: null,
          editSuccess: false,
          promocode: null,
        };
      }

      case ADD_REQUEST: {
        return { ...state, editLoading: true, editError: null, editSuccess: false };
      }

      case ACTIVATE_REQUEST: {
        return { ...state, editLoading: true, editError: null, editSuccess: false };
      }

      case EDIT_REQUEST: {
        return { ...state, editLoading: true, editError: null, editSuccess: false };
      }

      case EDIT_SUCCESS: {
        return { ...state, editLoading: false, editSuccess: true, promocode: action.payload };
      }

      case EDIT_FAIL: {
        return { ...state, editLoading: false, editError: action.payload };
      }

      case CLEAR_DEL_PROMOCODE: {
        return {
          ...state,
          delPromocodeLoading: false,
          delPromocodeError: null,
          delPromocodeSuccess: false,
        };
      }

      case DEL_PROMOCODE_REQUEST: {
        return {
          ...state,
          delPromocodeLoading: true,
          delPromocodeError: null,
          delPromocodeSuccess: false,
        };
      }

      case DEL_PROMOCODE_SUCCESS: {
        return { ...state, delPromocodeLoading: false, delPromocodeSuccess: true };
      }

      case DEL_PROMOCODE_FAIL: {
        return { ...state, delPromocodeLoading: false, delPromocodeError: action.payload };
      }

      default:
        return state;
    }
  }
);

export const actions = {
  clearFetch: () => createAction(CLEAR_FETCH),
  fetchRequest: (payload: {
    page: number;
    perPage: number;
    isFilter: boolean;
    filterData: IFilteredData;
    by_user: boolean;
  }) => createAction(FETCH_REQUEST, payload),
  fetchSuccess: (payload: IServerResponse<IPromocode[]>) =>
    createAction(FETCH_SUCCESS, payload),
  fetchFail: (payload: string) => createAction(FETCH_FAIL, payload),
  clearPromocodes: () => createAction(CLEAR_PROMOCODES),

  clearFetchById: () => createAction(CLEAR_FETCH_BY_ID),
  fetchByIdRequest: (payload: number) => createAction(FETCH_BY_ID_REQUEST, payload),
  fetchByIdSuccess: (payload: IServerResponse<IPromocode>) =>
    createAction(FETCH_BY_ID_SUCCESS, payload),
  fetchByIdFail: (payload: string) => createAction(FETCH_BY_ID_FAIL, payload),

  clearSearch: () => createAction(CLEAR_SEARCH),
  searchRequest: (payload: number) => createAction(SEARCH_REQUEST, payload),
  searchSuccess: (payload: IServerResponse<IPromocode[]>) =>
    createAction(SEARCH_SUCCESS, payload),
  searchFail: (payload: string) => createAction(SEARCH_FAIL, payload),

  clearEdit: () => createAction(CLEAR_EDIT),
  addRequest: (payload: IPromocodeAddProps) => createAction(ADD_REQUEST, payload),
  activateRequest: (payload: string) => createAction(ACTIVATE_REQUEST, payload),
  editRequest: (payload: { id: number; data: IPromocodeEditProps }) =>
    createAction(EDIT_REQUEST, payload),
  editSuccess: (data: IPromocode) => createAction(EDIT_SUCCESS, data),
  editFail: (payload: string) => createAction(EDIT_FAIL, payload),

  clearDelPromocode: () => createAction(CLEAR_DEL_PROMOCODE),
  delPromocodeRequest: (payload: number) => createAction(DEL_PROMOCODE_REQUEST, payload),
  delPromocodeSuccess: () => createAction(DEL_PROMOCODE_SUCCESS),
  delPromocodeFail: (payload: string) => createAction(DEL_PROMOCODE_FAIL, payload),
};

export type TActions = ActionsUnion<typeof actions>;

function* fetchSaga({
  payload,
}: {
  payload: {
    page: number;
    perPage: number;
    isFilter: boolean;
    filterData: IFilteredData;
    by_user: boolean;
  };
}) {
  try {
    const { data }: { data: IServerResponse<IPromocode[]> } = yield call(() =>
      getPromocodes(
        payload.page,
        payload.perPage,
        payload.isFilter,
        payload.filterData,
        payload.by_user
      )
    );
    yield put(actions.fetchSuccess(data));
  } catch (e) {
    yield put(actions.fetchFail(getResponseMessage(e)));
  }
}

function* fetchByIdSaga({ payload }: { payload: number }) {
  try {
    const { data }: { data: IServerResponse<IPromocode> } = yield call(() =>
      getPromocode(payload)
    );
    yield put(actions.fetchByIdSuccess(data));
  } catch (e) {
    yield put(actions.fetchByIdFail(getResponseMessage(e)));
  }
}

function* addSaga({ payload }: { payload: IPromocodeAddProps }) {
  try {
    const { data }: { data: IServerResponse<IPromocode> } = yield call(() =>
      createPromocode(payload)
    );
    yield put(actions.editSuccess(data.data));
  } catch (e) {
    yield put(actions.editFail(getResponseMessage(e)));
  }
}

function* activateSaga({ payload }: { payload: string }) {
  try {
    const { data }: { data: IServerResponse<IPromocode> } = yield call(() =>
      activatePromocode(payload)
    );
    yield put(actions.editSuccess(data.data));
  } catch (e) {
    yield put(actions.editFail(getResponseMessage(e)));
  }
}

function* editSaga({ payload }: { payload: { id: number; data: IPromocodeEditProps } }) {
  try {
    const { data }: { data: IServerResponse<IPromocode> } = yield call(() =>
      editPromocode(payload.id, payload.data)
    );
    yield put(actions.editSuccess(data.data));
  } catch (e) {
    yield put(actions.editFail(getResponseMessage(e)));
  }
}

export function* saga() {
  yield takeLatest<ReturnType<typeof actions.fetchRequest>>(FETCH_REQUEST, fetchSaga);
  yield takeLatest<ReturnType<typeof actions.addRequest>>(ADD_REQUEST, addSaga);
  yield takeLatest<ReturnType<typeof actions.activateRequest>>(ACTIVATE_REQUEST, activateSaga);
  yield takeLatest<ReturnType<typeof actions.editRequest>>(EDIT_REQUEST, editSaga);
  yield takeLatest<ReturnType<typeof actions.fetchByIdRequest>>(
    FETCH_BY_ID_REQUEST,
    fetchByIdSaga
  );
}
