import axios from 'axios';
import { ICreateSubscription, ITariffEditProps } from '../interfaces/tariff';
import { UserRoles } from '../interfaces/user';

const createTariffSubscription = ({
  tariff_id,
  days,
  payment_method_id,
}: ICreateSubscription) => {
  return `/api/stripe/add_subscription?days=${days}&tariff_id=${tariff_id}&payment_method_id=${payment_method_id}`;
};

export const getTariffs = (role: UserRoles) => {
  return axios.get(`/api/tariffs?role=${role}`);
};

export const editTariff = (id: number, data: ITariffEditProps) =>
  axios.put(`api/tariff/${id}`, data);

export const getTariffById = (id: number) => axios.get(`api/tariff/${id}`);

export const createPayment = (amount: number, tariff_id: number) =>
  axios.get(`/api/stripe/add_payment?amount=${amount}&tariff_id=${tariff_id}`);

export const createSubscription = (data: ICreateSubscription) =>
  axios.post(createTariffSubscription(data));

export const getStripeUser = (data: ICreateSubscription) =>
  axios.get(createTariffSubscription(data));

export const buyTariffGem = async (id: number, days: number) =>
  axios.post(`/api/buy_tariff/${id}?days=${days}`);

