import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {connect} from 'react-redux';
import objectPath from 'object-path';
import Topbar from './Topbar';
import HMenu from './HMenu/HMenu';
import KTHeader from '../../_assets/js/header';
import * as builder from '../../ducks/builder';
import {Input, makeStyles, TextField} from '@material-ui/core';
import {Link, withRouter} from 'react-router-dom';
import {useDefineUserRole} from '../../../app/hooks';
import {actions as productCatalogActions} from '../../../app/store/ducks/product-catalog.duck';
import {toAbsoluteUrl} from '../../utils/utils';
import {actions as productActions} from "../../../app/store/ducks/product.duck";
import SearchProducts from "./SearchProducts";

const useStyles = makeStyles(theme => ({
  subheaderFull: {
    left: '0 !important',
  },
  categoryItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 13,
    fontWeight: 400,
    color: '#441861',
    cursor: 'pointer',
    transition: 'all 0.3s ease',
    // backgroundColor: '#E4DDE8',
    padding: 20,
    height: 42,
    minWidth: 170,
    textTransform: 'uppercase',
    '&:hover': {
      transform: 'scale(1.05)',
    },
  },
  categoryItemTrait: {
    margin: '0 5px',
    color: '#441861',
    fontSize: 18,
    fontWeight: 500,
  },
  categoryItemActive: {
    fontWeight: 'bold',
  },
  categoryContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap',
    // gap: 10,
  },
}));

const Header = ({
                  menuHeaderDisplay,
                  headerAttributes,
                  headerClasses,
                  isAuthorized,
                  me,
                  location,
                  catalogCategories,
                  fetch,
                  setSearch,
                  categoryIdActive,
                  productsSearch,
                  loading,
                  focus,
                  setFocusSearch,
                  fetchSearch,
                  page,
                  per_page,
                  total,
                }) => {
  const headerCommonRef = useRef();
  const classes = useStyles();
  const isBuyer = useDefineUserRole(me, 'ROLE_BUYER');
  const filterCategory = useMemo(
    () =>
      catalogCategories
        .filter(category => category.products_num !== undefined && category.products_num > 0)
        .slice(0, 5),
    [catalogCategories]
  );

  const fetchProductCategory = useCallback(categoryId => {
    setSearch(true);
    categoryId &&
    fetch({
      page: 1,
      perPage: 12,
      categoryId: categoryId,
      activity: 'all',
    });
  }, []);

  useEffect(() => {
    const options = {};
    if (headerCommonRef.current.getAttribute('data-ktheader-minimize') === '1') {
      options['minimize.desktop.on'] = 'kt-header--minimize';
      options['offset.desktop'] = 130;
    }

    // eslint-disable-next-line no-undef
    new KTHeader(headerCommonRef.current, options);
  }, []);

  return (
    <div
      className={`kt-header kt-grid__item ${headerClasses} ${classes.subheaderFull}`}
      id='kt_header'
      ref={headerCommonRef}
      {...headerAttributes}
    >
      {/* <!-- begin: Header Menu --> */}
      <div
        className='kt-header-logo-wrapper'
        style={{display: 'flex', flexDirection: 'row', zIndex: 1000, position: 'relative'}}
      >
        {focus && (
          <div
            onClick={() => setFocusSearch(false)}
            style={{
              position: 'absolute',
              top: '100%',
              bottom: 0,
              left: 0,
              right: 0,
              height: '100vh',
              background: 'rgba(0,0,0,0.5)',
            }}/>
        )}

        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            maxWidth: 1440,
            width: '100%',
            margin: '0 auto',
            alignItems: 'center',
            height: 80,
          }}
        >
          <SearchProducts/>
          <div className='kt-header-logo'>
            <Link to={`/main/catalog`}>
              {/* <p style={{ margin: 0, fontWeight: '300', fontSize: 44, color: '#000', textTransform: 'uppercase'}}> */}
              {/*   Barelton */}
              {/* </p> */}
              <img
                alt='logo'
                src={toAbsoluteUrl(`/media/logos/logo-barelton.svg`)}
                height={34}
                width={296}
              />
            </Link>
          </div>
          {/* <!-- empty div to fix topbar to stay on the right when menu-horizontal is hidden --> */}
          <Topbar showBurger={isAuthorized} me={me}/>
          {/* <!-- end:: Header Topbar --> */}
        </div>
      </div>
      {!isAuthorized || isBuyer ? (
        <div
          className={`kt-subheader ${classes.categoryContainer}`}
          style={{
            maxWidth: 1440,
            margin: '0 auto',
          }}
        >
          {filterCategory.map((category, index) => (
            <>
              <Link
                to={`/products/catalog`}
                key={category.id}
                className={`${classes.categoryItem} ${
                  categoryIdActive === category.id ? classes.categoryItemActive : ''
                }`}
                onClick={() => fetchProductCategory(category.id)}
              >
                {category.name}
              </Link>
              {/* {(index < filterCategory.length - 1) && ( */}
              {/*   <span className={classes.categoryItemTrait}>|</span> */}
              {/* )} */}
            </>
          ))}
        </div>
      ) : (
        <div
          style={{display: 'flex', flex: 1, flexDirection: 'row', justifyContent: 'center'}}
        >
          {/* <!-- end: Header Menu --> */}
          {menuHeaderDisplay && (
            <div
              style={{
                alignItems: 'center',
                display: 'flex',
                padding: '0 25px',
              }}
            >
              <HMenu/>
            </div>
          )}
          {/* <!-- begin:: Header Topbar --> */}
        </div>
      )}
    </div>
  );
};

const connector = connect(
  store => ({
    me: store.profile.me,
    headerClasses: builder.selectors.getClasses(store, {
      path: 'header',
      toString: true,
    }),
    headerAttributes: builder.selectors.getAttributes(store, {path: 'header'}),
    menuHeaderDisplay: objectPath.get(store.builder.layoutConfig, 'header.menu.self.display'),
    fluid: objectPath.get(store.builder.layoutConfig, 'header.self.width') === 'fluid',
    isAuthorized: store.auth.user != null,
    catalogCategories: store.categories.catalogCategories,
    categoryIdActive: store.productsCatalog.categoryIdActive,

    productsSearch: store.products.productsSearch,
    loading: store.products.productsSearchLoading,
    page: store.products.productsSearchPage,
    per_page: store.products.productsSearchPerPage,
    total: store.products.productsSearchTotalPage,
    focus: store.products.searchFocus,
  }),
  {
    fetch: productCatalogActions.fetchRequest,
    setSearch: productCatalogActions.setSearch,
    setFocusSearch: productActions.setFocusSearch,
  }
);

// const mapStateToProps = store => ({
//   me: store.profile.me,
//   headerClasses: builder.selectors.getClasses(store, {
//     path: 'header',
//     toString: true,
//   }),
//   headerAttributes: builder.selectors.getAttributes(store, { path: 'header' }),
//   menuHeaderDisplay: objectPath.get(store.builder.layoutConfig, 'header.menu.self.display'),
//   fluid: objectPath.get(store.builder.layoutConfig, 'header.self.width') === 'fluid',
//   isAuthorized: store.auth.user != null,
//   catalogCategories: store.categories.catalogCategories,
// });

export default withRouter(connector(Header));
