import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { Reducer } from 'redux';
import { PersistPartial } from 'redux-persist/es/persistReducer';
import { put, takeLatest, call } from 'redux-saga/effects';

import { TAppActions } from '../rootDuck';

import { ActionsUnion, createAction } from '../../utils/action-helper';
import { getDeliveries, getDeliveryById } from '../../crud/delivery.crud';
import { IServerResponse } from '../../interfaces/server';
import { getResponseMessage } from '../../utils/utils';
import { IDelivery } from '../../interfaces/delivery';

const FETCH_REQUEST = 'delivery/FETCH_REQUEST';
const FETCH_SUCCESS = 'delivery/FETCH_SUCCESS';
const FETCH_FAIL = 'delivery/FETCH_FAIL';

const FETCH_DOC_REQUEST = 'delivery/FETCH_DOC_REQUEST';
const FETCH_DOC_SUCCESS = 'delivery/FETCH_DOC_SUCCESS';
const FETCH_DOC_FAIL = 'delivery/FETCH_DOC_FAIL';

export interface IInitialState {
  page: number;
  per_page: number;
  total: number;
  delivery: IDelivery[] | undefined;
  loading: boolean;
  success: boolean;
  error: string | null;
  deliveryById: IDelivery | undefined;
}

const initialState: IInitialState = {
  page: 1,
  per_page: 20,
  total: 0,
  delivery: undefined,
  loading: false,
  success: false,
  error: null,
  deliveryById: undefined,
};

export const reducer: Reducer<IInitialState & PersistPartial, TAppActions> = persistReducer(
  { storage, key: 'models', whitelist: ['user', 'authToken'] },
  (state = initialState, action) => {
    switch (action.type) {
      case FETCH_REQUEST: {
        return {
          ...state,
          delivery: undefined,
          loading: true,
          success: false,
          error: null,
        };
      }

      case FETCH_SUCCESS: {
        return {
          ...state,
          page: action.payload.page,
          per_page: action.payload.per_page,
          total: action.payload.total,
          delivery: action.payload.data,
          loading: false,
          success: true,
        };
      }

      case FETCH_FAIL: {
        return { ...state, loading: false, error: action.payload };
      }

      case FETCH_DOC_REQUEST: {
        return {
          ...state,
          deliveryById: undefined,
          loading: true,
          success: false,
          error: null,
        };
      }

      case FETCH_DOC_SUCCESS: {
        return {
          ...state,
          page: action.payload.page,
          per_page: action.payload.per_page,
          total: action.payload.total,
          deliveryById: action.payload.data,
          loading: false,
          success: true,
        };
      }

      case FETCH_DOC_FAIL: {
        return { ...state, loading: false, error: action.payload };
      }

      default:
        return state;
    }
  }
);

export const actions = {
  fetchRequest: (payload: { page: number; perPage: number }) =>
    createAction(FETCH_REQUEST, payload),
  fetchSuccess: (payload: IServerResponse<IDelivery[]>) =>
    createAction(FETCH_SUCCESS, payload),
  fetchFail: (payload: string) => createAction(FETCH_FAIL, payload),
  fetchRequestById: (payload: { id: number }) => createAction(FETCH_DOC_REQUEST, payload),
  fetchByIdSuccess: (payload: IServerResponse<IDelivery[]>) =>
    createAction(FETCH_DOC_SUCCESS, payload),
  fetchByIdFail: (payload: string) => createAction(FETCH_DOC_FAIL, payload),
};

export type TActions = ActionsUnion<typeof actions>;

function* fetchSaga({ payload }: { payload: { page: number; perPage: number } }) {
  try {
    const { data }: { data: IServerResponse<IDelivery[]> } = yield call(() =>
      getDeliveries(payload.page, payload.perPage)
    );
    yield put(actions.fetchSuccess(data));
  } catch (e) {
    yield put(actions.fetchFail(getResponseMessage(e)));
  }
}

function* fetchByIdSaga({ payload }: { payload: { id: number } }) {
  try {
    const { data }: { data: IServerResponse<IDelivery[]> } = yield call(() =>
      getDeliveryById(payload.id)
    );
    yield put(actions.fetchByIdSuccess(data));
  } catch (e) {
    yield put(actions.fetchByIdFail(getResponseMessage(e)));
  }
}

export function* saga() {
  yield takeLatest<ReturnType<typeof actions.fetchRequest>>(FETCH_REQUEST, fetchSaga);
  yield takeLatest<ReturnType<typeof actions.fetchRequestById>>(
    FETCH_DOC_REQUEST,
    fetchByIdSaga
  );
}
