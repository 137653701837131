import axios from 'axios';
import { ME_URL } from '../constants';
import { IUserEditProps } from '../interfaces/user';

export function getMe() {
  return axios.get(ME_URL);
}

export function editMe(data: IUserEditProps) {
  return axios.put(ME_URL, data);
}

export function uploadAvatar(data: FormData) {
  return axios.post(ME_URL + '/upload_photos', data);
}
