import React, {useCallback, useState} from 'react';
import { useHistory } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { Button, Card, Typography } from '@material-ui/core';

import clsx from "clsx";
import { thousands } from '../../../../../utils/utils';
import { useDefineUserRole, useFormatMessage } from '../../../../../hooks';
import { toAbsoluteUrl } from '../../../../../../_metronic';
import { IUser } from '../../../../../interfaces/user';
import { IProduct } from '../../../../../interfaces/product';
import { useStylesProducViewNoWine } from '../../hooks/useStylesProductViewNoWine';
import ImageGalleryNoWine from '../ImageGalleryNoWine';
import Preloader from "../../../../../components/ui/Preloader/Preloader";
import ProductsGrid from "../../../main/components/ProductsGrid";
import {useStylesProductCatalog} from "../../../main/hooks/useStyles";
import {ICart, IGuestCart} from "../../../cart/interfaces";

type TProps = {
  me?: IUser;
  product: IProduct;
  getPosition: () => void;
  isAuthorized: boolean;
  productCount: number;
  setProductCountLoading: boolean;
  handleSetCountCart: (type: 'dec' | 'inc', count: number, product: IProduct) => void;
  addProductLoading: boolean;
  handleCartDialog: (item: any) => void;
  countries?: any;
  loadingSimilar?:boolean;
  products?:IProduct[];
  isAdmin:boolean;
  cart?: ICart | null;
  guestCart?: IGuestCart | null;

};

const NoWine: React.FC<TProps> = ({
  me,
  product,
  getPosition,
  isAuthorized,
  productCount,
  setProductCountLoading,
  handleSetCountCart,
  addProductLoading,
  handleCartDialog,
  countries,
  loadingSimilar,
  products,
  isAdmin,
                                    cart,
                                    guestCart,
}) => {
  const intl = useIntl();
  const classes = useStylesProducViewNoWine();
  const classesCatalog = useStylesProductCatalog();
  const history = useHistory();
  const fm = useFormatMessage();
  const isBuyer = useDefineUserRole(me, 'ROLE_BUYER');
  const [hideText, setHideText] = useState(false);
  const viewActionProduct = useCallback(
      (item: any, type?: string) => {
        history.push(`/viewproduct/${item.id}`, { state: { type } });
      },
      [history]
  );

  return (
    <>
      <div className={classes.container}>
        <Card className={classes.card}>
          <div className={classes.header}>
            <p className={classes.name}>{product.name}</p>
          </div>
          <div className={classes.containerProduct}>
            <div style={{ width: '100%' }}>
              <img
                src={toAbsoluteUrl('/images/landscape.jpg')}
                alt='landscape'
                className={classes.imgBackground}
                onLoad={() => getPosition()}
              />
              <div className={classes.body}>
                <div className={classes.leftCol}>
                  <ImageGalleryNoWine product={product} />
                </div>
                <div className={classes.rightCol}>
                  <div className={classes.wrapperPrice}>
                    <div className={classes.containerPrice}>
                      {!!product.old_price && !!product.discount_price && (
                        <p className={classes.newPriceTitle}>
                          CZK {thousands(product.old_price.toString())}
                        </p>
                      )}
                      <p className={classes.priceTitle}>
                        CZK {thousands(product.price.toString())}
                      </p>
                    </div>
                    {(isBuyer || !isAuthorized) && productCount > 0 && (
                      <div className={classes.addFav}>
                        <button
                        disabled={setProductCountLoading}
                        onClick={() =>
                          // eslint-disable-next-line no-plusplus
                            handleSetCountCart('dec', --productCount!, product)
                          // handleSetCountCart(--productCount!, product)
                        }
                        className={classes.favBtn}
                        type='button'
                      >
                        -
                      </button>
                      <p className={classes.favText}>{productCount}</p>
                      <button
                        disabled={setProductCountLoading}
                        onClick={() =>
                          // eslint-disable-next-line no-plusplus
                          // handleSetCountCart(++productCount!, product)
                            // eslint-disable-next-line no-plusplus
                            handleSetCountCart('inc', ++productCount!, product)
                        }
                        className={classes.favBtn}
                        type='button'
                      >
                        +
                      </button>
                    </div>
                  )}
                  {(isBuyer || !isAuthorized) && (
                    <Button
                      disabled={addProductLoading}
                      onClick={event => {
                        if (productCount > 0) {
                          event.stopPropagation();
                          history.push('/cart');
                        } else {
                          event.stopPropagation();
                          handleCartDialog(product);
                        }
                      }}
                      color='primary'
                      className={classes.addBtn}
                    >
                      <div className={classes.containerAddProduct}>
                        <img src={toAbsoluteUrl('/images/basket.svg')} alt='' />
                        {productCount > 0
                          ? fm('CART.SUBHEADER.TITLE')
                          : fm('PRODUCT.BUTTON.ADD_CART')}
                      </div>
                    </Button>
                  )}
                  </div>
                </div>
              </div>
            </div>
            <div className={classes.productInfo}>
              {(product.product_type ||
                (product.parameter_values && product.parameter_values.length > 0)) && (
                <div className={classes.containerParams}>
                  {product.product_type && (
                    <div className={classes.containerParam}>
                      <Typography className={classes.paramValue}>
                        {product.product_type.name || ''}
                      </Typography>
                      <Typography className={classes.paramName}>
                        {fm('PRODUCT.EDIT.TYPE')}
                      </Typography>
                    </div>
                  )}
                  {product.parameter_values?.map(i => (
                    <div key={i.id?.toString() || ''} className={classes.containerParam}>
                      <Typography className={classes.paramValue}>
                        {i.parameter_type === 'country'
                          ? countries?.find((el: any) => el.ru_name === i.value)?.en_name ||
                            i.value?.toString() ||
                            ''
                          : i.value?.toString() || ''}
                      </Typography>
                      <Typography className={classes.paramName}>{i.parameter_name}</Typography>
                    </div>
                  ))}
                </div>
              )}
              {product.description && (
                <div className={classes.degree}>
                  <div className={classes.degreeBlock}>
                    <div
                      style={{
                        // eslint-disable-next-line no-nested-ternary
                        WebkitLineClamp: hideText ? 300 : 3,
                      }}
                      dangerouslySetInnerHTML={{ __html: product.description }}
                      className={classes.degreeText}
                    ></div>
                    <Typography
                      style={{ marginTop: !hideText ? '1rem' : 0, cursor: 'pointer' }}
                      onClick={() => setHideText(!hideText)}
                    >
                      {!hideText
                        ? intl.formatMessage({ id: 'PRODUCT.SHOW.ALL' })
                        : intl.formatMessage({ id: 'PRODUCT.HIDE' })}
                    </Typography>
                  </div>
                </div>
              )}
            </div>
          </div>
        </Card>
         {loadingSimilar ? (
            <Preloader />
         ) : (
            <>
              {!products || !products.length ? (
                  <></>
              ) : (
                  <div
                      className={clsx(classesCatalog.card, {
                        [classesCatalog.card1elm]: true,
                      })}
                  >
                    <ProductsGrid
                        addProductLoading={addProductLoading}
                        cart={cart}
                        guestCart={guestCart}
                        setProductCountLoading={setProductCountLoading}
                        handleCartDialog={handleCartDialog}
                        handleSetCountCart={handleSetCountCart}
                        isAuthorized={isAuthorized}
                        isAdmin={isAdmin!!}
                        me={me}
                        viewAction={viewActionProduct}
                        data={products}
                    />
                  </div>
              )}
            </>
         )}
      </div>
    </>
  );
};

export default NoWine;
