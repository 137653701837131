import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { Reducer } from 'redux';
import { PersistPartial } from 'redux-persist/es/persistReducer';
import { call, put, takeLatest } from 'redux-saga/effects';
import { TAppActions } from '../rootDuck';
import { ActionsUnion, createAction } from '../../utils/action-helper';
import { getResponseMessage } from '../../utils/utils';
import {
  addProduct,
  createSupply,
  fetchAccordSupply,
  fetchSupply,
} from '../../crud/supply.crud';
import { IServerResponse } from '../../interfaces/server';
import { IAccord, IStore } from '../../interfaces/store';
import { ISupply } from '../../pages/home/supply/interfaces';

const CLEAR_ADD_PRODUCT = 'supply/CLEAR_ADD_PRODUCT';
const ADD_PRODUCT_REQUEST = 'supply/ADD_PRODUCT_REQUEST';
const ADD_PRODUCT_SUCCESS_SUPPLY = 'supply/ADD_PRODUCT_SUCCESS_SUPPLY';
const ADD_PRODUCT_FAIL_SUPPLY = 'supply/ADD_PRODUCT_FAIL_SUPPLY';

const GET_OR_CREATE_SUPPLY_REQUEST = 'supply/GET_OR_CREATE_SUPPLY_REQUEST';

const CLEAR_CHECKOUT = 'supply/CLEAR_CHECKOUT';
const CHECKOUT_REQUEST = 'supply/CHECKOUT_REQUEST';
const CHECKOUT_SUCCESS = 'supply/CHECKOUT_SUCCESS';
const CHECKOUT_FAIL = 'supply/CHECKOUT_FAIL';

const CLEAR_STORES = 'supply/CLEAR_STORES';
const STORES_REQUEST = 'supply/STORES_REQUEST';
const STORES_SUCCESS = 'supply/STORES_SUCCESS';
const STORES_FAIL = 'supply/STORES_FAIL';

const FETCH_REQUEST = 'supply/FETCH_REQUEST';
const FETCH_SUCCESS = 'supply/FETCH_SUCCESS';
const FETCH_FAIL = 'supply/FETCH_FAIL';

const FETCH_ACCORD_REQUEST = 'supply/FETCH_ACCORD_REQUEST';
const FETCH_ACCORD_SUCCESS = 'supply/FETCH_ACCORD__SUCCESS';
const FETCH_ACCORD_FAIL = 'supply/FETCH_ACCORD__FAIL';

export interface IInitialState {
  // supply: ISupply | null;
  companyId: number | null;
  stores: IStore[];
  accord: IAccord | null;

  loading: boolean;

  addProductLoading: boolean;
  addProductSuccess: boolean;
  addProductError: string | null;

  checkoutLoading: boolean;
  checkoutSuccess: boolean;
  checkoutError: string | null;

  storesLoading: boolean;
  storesSuccess: boolean;
  storesError: string | null;
}

const initialState: IInitialState = {
  // supply: null,
  companyId: null,
  stores: [],
  accord: null,

  loading: false,

  addProductLoading: false,
  addProductSuccess: false,
  addProductError: null,

  checkoutLoading: false,
  checkoutSuccess: false,
  checkoutError: null,

  storesLoading: false,
  storesSuccess: false,
  storesError: null,
};

export const reducer: Reducer<IInitialState & PersistPartial, TAppActions> = persistReducer(
  { storage, key: 'orders', whitelist: ['user', 'authToken'] },
  (state = initialState, action) => {
    switch (action.type) {
      case CLEAR_ADD_PRODUCT: {
        return {
          ...state,
          addProductLoading: false,
          addProductSuccess: false,
          addProductError: null,
        };
      }
      case ADD_PRODUCT_REQUEST: {
        return {
          ...state,
          addProductLoading: true,
          addProductSuccess: false,
          addProductError: null,
        };
      }
      case ADD_PRODUCT_SUCCESS_SUPPLY: {
        const supply: any = action.payload.data;
        const newStores = state.stores.map(item =>
          item.id === supply.id ? { ...item, ...supply } : item
        );
        return {
          ...state,
          supply,
          stores: newStores,
          addProductLoading: false,
          addProductSuccess: true,
          addProductError: null,
        };
      }
      case ADD_PRODUCT_FAIL_SUPPLY: {
        return {
          ...state,
          addProductLoading: false,
          addProductSuccess: false,
          addProductError: action.payload,
        };
      }
      case GET_OR_CREATE_SUPPLY_REQUEST: {
        return {
          ...state,
          getOrCreateCartLoading: true,
          getOrCreateCartSuccess: false,
          getOrCreateCartError: null,
        };
      }

      case CLEAR_CHECKOUT: {
        return {
          ...state,
          checkoutLoading: false,
          checkoutSuccess: false,
          checkoutError: null,
        };
      }
      case CHECKOUT_REQUEST: {
        return {
          ...state,
          checkoutLoading: true,
          checkoutSuccess: false,
          checkoutError: null,
        };
      }
      case CHECKOUT_SUCCESS: {
        localStorage.setItem('hash', action.payload.data.cart_hash);

        return {
          ...state,
          cart: action.payload.data,
          companyId: null,
          checkoutLoading: false,
          checkoutSuccess: true,
          checkoutError: null,
        };
      }
      case CHECKOUT_FAIL: {
        return {
          ...state,
          checkoutLoading: false,
          checkoutSuccess: false,
          checkoutError: action.payload,
        };
      }

      case CLEAR_STORES: {
        return {
          ...state,
          stores: [],
          storesLoading: false,
          storesSuccess: false,
          storesError: null,
        };
      }
      case STORES_REQUEST: {
        return {
          ...state,
          storesLoading: true,
          storesSuccess: false,
          storesError: null,
        };
      }
      case STORES_SUCCESS: {
        return {
          ...state,
          stores: action.payload.data,
          storesLoading: false,
          storesSuccess: true,
          storesError: null,
        };
      }
      case STORES_FAIL: {
        return {
          ...state,
          storesLoading: false,
          storesSuccess: false,
          storesError: action.payload,
        };
      }

      case FETCH_REQUEST: {
        return {
          ...state,
          fetchLoading: true,
          fetchSuccess: false,
          fetchError: null,
        };
      }
      case FETCH_SUCCESS: {
        return {
          ...state,
          fetch: action.payload.data,
          fetchLoading: false,
          fetchSuccess: true,
          fetchError: null,
        };
      }
      case FETCH_FAIL: {
        return {
          ...state,
          fetchLoading: false,
          fetchSuccess: false,
          fetchError: action.payload,
        };
      }

      case FETCH_ACCORD_REQUEST: {
        return {
          ...state,
          fetchAccordLoading: true,
          fetchAccordSuccess: false,
          fetchAccordError: null,
        };
      }
      case FETCH_ACCORD_SUCCESS: {
        return {
          ...state,
          accord: action.payload.data,
          fetchAccordLoading: false,
          fetchAccordSuccess: true,
          fetchAccordError: null,
        };
      }
      case FETCH_ACCORD_FAIL: {
        return {
          ...state,
          fetchAccordLoading: false,
          fetchAccordSuccess: false,
          fetchAccordError: action.payload,
        };
      }

      default:
        return state;
    }
  }
);

export const actions = {
  clearAddProduct: () => createAction(CLEAR_ADD_PRODUCT),
  addProductRequest: (payload: { product_id: number; count: number; newCart?: boolean }) => {
    return createAction(ADD_PRODUCT_REQUEST, payload);
  },
  addProductSuccess: (payload: IServerResponse<ISupply>) =>
    createAction(ADD_PRODUCT_SUCCESS_SUPPLY, payload),
  addProductFail: (payload: string) => createAction(ADD_PRODUCT_FAIL_SUPPLY, payload),

  getOrCreateRequest: () => createAction(GET_OR_CREATE_SUPPLY_REQUEST),
  fetchAccord: () => createAction(FETCH_ACCORD_REQUEST),
  fetchAccordSuccess: (payload: IServerResponse<any>) =>
    createAction(FETCH_ACCORD_SUCCESS, payload),
  fetchAccordFail: (payload: string) => createAction(FETCH_ACCORD_FAIL, payload),

  fetchRequest: () => createAction(FETCH_REQUEST),
  fetchSuccess: (payload: IServerResponse<any>) => createAction(FETCH_SUCCESS, payload),
  fetchFail: (payload: string) => createAction(FETCH_FAIL, payload),

  clearCheckout: () => createAction(CLEAR_CHECKOUT),
  checkoutRequest: (payload: { id: number; status: string; comment: string }) =>
    createAction(CHECKOUT_REQUEST, payload),
  checkoutSuccess: (payload: IServerResponse<ISupply>) =>
    createAction(CHECKOUT_SUCCESS, payload),
  checkoutFail: (payload: string) => createAction(CHECKOUT_FAIL, payload),

  clearStores: () => createAction(CLEAR_STORES),
  storesRequest: (payload: { companyId: number }) => createAction(STORES_REQUEST, payload),
  storesSuccess: (payload: IServerResponse<IStore[]>) => createAction(STORES_SUCCESS, payload),
  storesFail: (payload: string) => createAction(STORES_FAIL, payload),
};

export type TActions = ActionsUnion<typeof actions>;

function* addProductSaga({
  payload,
}: {
  payload: { product_id: number; count: number; newCart?: boolean };
}) {
  try {
    const { data }: { data: IServerResponse<any> } = yield call(() =>
      addProduct({ product_id: payload.product_id, count: payload.count })
    );
    yield put(actions.addProductSuccess(data));
    yield put(actions.clearAddProduct());
  } catch (e) {
    yield put(actions.addProductFail(getResponseMessage(e)));
  }
}

function* checkoutSaga({
  payload,
}: {
  payload: { id: number; status: string; comment: string };
}) {
  try {
    const { data }: { data: IServerResponse<any> } = yield call(() =>
      createSupply({ id: payload.id, status: payload.status, comment: payload.comment })
    );
    yield put(actions.checkoutSuccess(data));
  } catch (e) {
    yield put(actions.checkoutFail(getResponseMessage(e)));
  }
}

function* fetchSaga() {
  try {
    const { data }: { data: IServerResponse<any> } = yield call(() => fetchSupply());
    yield put(actions.fetchSuccess(data));
    yield put(actions.storesSuccess(data));
  } catch (e) {
    yield put(actions.fetchFail(getResponseMessage(e)));
    yield put(actions.storesFail(getResponseMessage(e)));
  }
}

function* fetchAccordSaga() {
  try {
    const { data }: { data: IServerResponse<any> } = yield call(() => fetchAccordSupply());
    yield put(actions.fetchAccordSuccess(data));
  } catch (e) {
    yield put(actions.fetchAccordFail(getResponseMessage(e)));
  }
}

export function* saga() {
  yield takeLatest<ReturnType<typeof actions.addProductRequest>>(
    ADD_PRODUCT_REQUEST,
    addProductSaga
  );
  yield takeLatest<ReturnType<typeof actions.checkoutRequest>>(CHECKOUT_REQUEST, checkoutSaga);
  yield takeLatest<ReturnType<typeof actions.fetchRequest>>(FETCH_REQUEST, fetchSaga);
  yield takeLatest<ReturnType<typeof actions.fetchAccord>>(
    FETCH_ACCORD_REQUEST,
    fetchAccordSaga
  );
}
