import axios from 'axios';
import {
  GET_PROMOCODES_URL,
  CREATE_PROMOCODE_URL,
  editPromocodeUrl,
  getPromocodeUrl,
  activatePromocodeUrl,
  GET_PROMOCODES_STAT_URL,
  getPromocodeStatUrl,
} from '../constants';

import { IPromocodeEditProps, IPromocodeAddProps, IFilteredData } from '../interfaces/promocodes';

export function getPromocodes(
  page: number,
  perPage: number,
  isFilter: boolean = false,
  filterData: IFilteredData,
  by_user: boolean
) {
  if (isFilter) {
    return axios.get(
      `${GET_PROMOCODES_STAT_URL}?page=${page}&per_page=${perPage}&start_date=${filterData.start_date}&end_date=${filterData.end_date}&by_user=${by_user}`
    );
  }
  return axios.get(
    `${GET_PROMOCODES_URL}?page=${page}&per_page=${perPage}&by_user=${by_user}`
  );
}

export function createPromocode(data: IPromocodeAddProps) {
  return axios.post(CREATE_PROMOCODE_URL, data);
}

export function editPromocode(id: number, data: IPromocodeEditProps) {
  return axios.put(editPromocodeUrl(id), data);
}

export function getPromocode(id: number) {
  return axios.get(getPromocodeUrl(id));
}

export function activatePromocode(code: string) {
  return axios.post(activatePromocodeUrl(code));
}

export function addBonusesUser(user_id: number, amount: number) {
  return axios.post('/api/transaction', {
    amount,
    user_id,
  });
}

export function getPromocodeStat(id: number) {
  return axios.get(getPromocodeStatUrl(id));
}

export function getPromocodeSettings() {
  return axios.get('/api/promo_codes/settings');
}

export function editPromocodeSettings(data: { amount: number; bonus: number }) {
  return axios.put(
    '/api/promo_codes/settings',
    {},
    {
      params: {
        amount: data.amount,
        bonus: data.bonus,
      },
    }
  );
}
