import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { Reducer } from 'redux';
import { PersistPartial } from 'redux-persist/es/persistReducer';
import { put, takeLatest, call } from 'redux-saga/effects';

import { TAppActions } from '../rootDuck';

import { ActionsUnion, createAction } from '../../utils/action-helper';
import { IServerResponse } from '../../interfaces/server';
import { IUser, IUserEditProps } from '../../interfaces/user';
import { getMe, editMe, uploadAvatar } from '../../crud/profile.crud';
import { getResponseMessage } from '../../utils/utils';

const CLEAR_ME = 'profile/CLEAR_ME';
const FETCH_REQUEST = 'profile/FETCH_REQUEST';
const FETCH_SUCCESS = 'profile/FETCH_SUCCESS';
const FETCH_FAIL = 'profile/FETCH_FAIL';

const CLEAR_EDIT = 'profile/CLEAR_EDIT';
const EDIT_REQUEST = 'profile/EDIT_REQUEST';
const EDIT_SUCCESS = 'profile/EDIT_SUCCESS';
const EDIT_FAIL = 'profile/EDIT_FAIL';

const UPLOAD_AVATAR_REQUEST = 'profile/UPLOAD_AVATAR_REQUEST';
const UPLOAD_AVATAR_SUCCESS = 'profile/UPLOAD_AVATAR_SUCCESS';
const UPLOAD_AVATAR_FAIL = 'profile/UPLOAD_AVATAR_FAIL';

export interface IInitialState {
  me: IUser | undefined;
  loading: boolean;
  success: boolean;
  error: string | null;

  editLoading: boolean;
  editSuccess: boolean;
  editError: string | null;

  uploadAvatarLoading: boolean;
  uploadAvatarSuccess: boolean;
  uploadAvatarError: string | null;
}

const initialState: IInitialState = {
  me: undefined,
  loading: false,
  success: false,
  error: null,

  editLoading: false,
  editSuccess: false,
  editError: null,

  uploadAvatarLoading: false,
  uploadAvatarSuccess: false,
  uploadAvatarError: null,
};

export const reducer: Reducer<IInitialState & PersistPartial, TAppActions> = persistReducer(
  { storage, key: 'profile', whitelist: ['user', 'authToken'] },
  (state = initialState, action) => {
    switch (action.type) {
      case CLEAR_ME: {
        return { ...state, loading: false, success: false };
      }

      case FETCH_REQUEST: {
        return {
          ...state,
          me: undefined,
          loading: true,
          error: null,
          success: false,
        };
      }

      case FETCH_SUCCESS: {
        const me = action.payload.data.id ? action.payload.data : undefined;
        return { ...state, me, loading: false, success: true };
      }

      case FETCH_FAIL: {
        return { ...state, loading: false, error: action.payload, success: false };
      }

      case CLEAR_EDIT: {
        return { ...state, editLoading: false, editError: null, editSuccess: false };
      }

      case EDIT_REQUEST: {
        return { ...state, editLoading: true, editError: null, editSuccess: false };
      }

      case EDIT_SUCCESS: {
        let me = action.payload.data.id ? action.payload.data : undefined;

        if (state.me) {
          me = {
            ...state.me,
            ...me,
          };
        }

        return { ...state, editLoading: false, editSuccess: true, me };
      }

      case EDIT_FAIL: {
        return { ...state, editLoading: false, editSuccess: false, editError: action.payload };
      }

      case UPLOAD_AVATAR_REQUEST: {
        return {
          ...state,
          uploadAvatarLoading: true,
          uploadAvatarError: null,
          uploadAvatarSuccess: false,
        };
      }

      case UPLOAD_AVATAR_SUCCESS: {
        return {
          ...state,
          me: action.payload.data,
          uploadAvatarLoading: false,
          uploadAvatarError: null,
          uploadAvatarSuccess: true,
        };
      }

      case UPLOAD_AVATAR_FAIL: {
        return {
          ...state,
          uploadAvatarLoading: false,
          uploadAvatarError: action.payload,
          uploadAvatarSuccess: false,
        };
      }

      default:
        return state;
    }
  }
);

export const actions = {
  clearMe: () => createAction(CLEAR_ME),
  fetchRequest: () => createAction(FETCH_REQUEST),
  fetchSuccess: (payload: IServerResponse<IUser>) => createAction(FETCH_SUCCESS, payload),
  fetchFail: (payload: string) => createAction(FETCH_FAIL, payload),

  clearEdit: () => createAction(CLEAR_EDIT),
  editRequest: (payload: IUserEditProps) => createAction(EDIT_REQUEST, payload),
  editSuccess: (payload: IServerResponse<IUser>) => createAction(EDIT_SUCCESS, payload),
  editFail: (payload: string) => createAction(EDIT_FAIL, payload),

  uploadAvatarRequest: (payload: FormData) => createAction(UPLOAD_AVATAR_REQUEST, payload),
  uploadAvatarSuccess: (payload: IServerResponse<IUser>) =>
    createAction(UPLOAD_AVATAR_SUCCESS, payload),
  uploadAvatarFail: (payload: string) => createAction(UPLOAD_AVATAR_FAIL, payload),
};

export type TActions = ActionsUnion<typeof actions>;

function* fetchSaga() {
  try {
    const { data }: { data: IServerResponse<IUser> } = yield call(() => getMe());
    yield put(actions.fetchSuccess(data));
  } catch (e) {
    yield put(actions.fetchFail(getResponseMessage(e)));
  }
}

function* editSaga({ payload }: { payload: IUserEditProps }) {
  try {
    const { data }: { data: IServerResponse<IUser> } = yield call(() => editMe(payload));
    yield put(actions.editSuccess(data));
  } catch (e) {
    yield put(actions.editFail(getResponseMessage(e)));
  }
}

function* uploadAvatarSaga({ payload }: { payload: FormData }) {
  try {
    const { data }: { data: IServerResponse<IUser> } = yield call(() => uploadAvatar(payload));
    yield put(actions.uploadAvatarSuccess(data));
  } catch (e) {
    yield put(actions.uploadAvatarFail(e?.response?.data?.message || 'Network error'));
  }
}

export function* saga() {
  yield takeLatest<ReturnType<typeof actions.fetchRequest>>(FETCH_REQUEST, fetchSaga);
  yield takeLatest<ReturnType<typeof actions.editRequest>>(EDIT_REQUEST, editSaga);
  yield takeLatest<ReturnType<typeof actions.uploadAvatarRequest>>(
    UPLOAD_AVATAR_REQUEST,
    uploadAvatarSaga
  );
}
