import React, { useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';
import { createStyles, makeStyles } from '@material-ui/core';
import { IAppState } from '../../../store/rootDuck';
import { actions as categoriesActions } from '../../../store/ducks/categories.duck';
import Preloader from '../../ui/Preloader/Preloader';
import { Tree } from './components';

const useStyles = makeStyles(
  createStyles({
    emptyPaper: {
      minHeight: 2,
      display: 'flex',
      alignItems: 'center',
    },
  })
);

const ProductCatalogList: React.FC<TPropsFromRedux & RouteComponentProps> = ({
  match: { url },
  categories,
  loading,
  fetch,
  clear,
}) => {
  const classes = useStyles();

  useEffect(() => {
    fetch();

    return () => {
      clear();
    };
  }, []);

  if (loading) {
    return (
      <Row>
        <Col className={classes.emptyPaper}>
          <Preloader />
        </Col>
      </Row>
    );
  }

  return (
    <>
      <ul className='kt-menu__subnav' style={{ paddingLeft: 17, listStyle: 'none' }}>
        <Tree categories={categories} activeId={url} />
      </ul>
    </>
  );
};

const connector = connect(
  (states: IAppState) => ({
    categories: states.categories.catalogCategories,
    loading: states.categories.catalogLoading,
    error: states.categories.catalogError,
    success: states.categories.catalogSuccess,
  }),
  {
    fetch: categoriesActions.fetchCatalogCategories,
    clear: categoriesActions.clearCatalogCategories,
  }
);

type TPropsFromRedux = ConnectedProps<typeof connector>;

export default withRouter(connector(ProductCatalogList));
