import axios from 'axios';

import { ICreateBanner, IEditBanner } from '../pages/home/banners/interfaces';

export const createBanner = (body: ICreateBanner) => {
  return axios.post(`/api/shop/banner`, body);
};

export const editBanner = (id: number, data: IEditBanner) => {
  return axios.post(`/api/shop/banner/${id}`, data);
};

export const deleteBanner = (id: number) => axios.delete(`/api/shop/banner/${id}`);

export const getBanner = (id: number) => axios.get(`/api/shop/banner/${id}`);

export function getBanners(body: { page?: number | 1; perPage?: number | 20 }) {
  return axios.get(`/api/shop/banners?page=${body.page}&per_page=${body.perPage}`);
}
