import { CHAT_SOCKET } from '../constants';
import { getMyAuthToken } from '../crud/chat.crud';
import { IServerResponse } from '../interfaces/server';
import store from '../store/store';
import { getResponseMessage } from './utils';
import { actions as chatActions } from '../store/ducks/chat.duck';

export default class ChatSocket {
  private static ws: WebSocket | null = null;

  public static myAuthToken: string | null = null;

  public static isConnectedToSocket: boolean = false;

  // SOCKET METODS

  public static async getMyAuthToken() {
    try {
      const { data }: { data: IServerResponse<any> } = await getMyAuthToken();
      this.myAuthToken = data.data.token;
    } catch (e) {
      console.log('error authToken', getResponseMessage(e));
    }
  }

  public static async connectToSocket() {
    try {
      console.log('connect to socket...');
      if (this.ws !== null) {
        console.log('already exists');
        return;
      }
      await this.getMyAuthToken();
      this.ws = new WebSocket(CHAT_SOCKET);
      const authChallenge = {
        seq: 1,
        action: 'authentication_challenge',
        data: { token: this.myAuthToken },
      };

      this.ws.addEventListener('open', () => {
        console.log('- ws send auth challenge');
        this.ws?.send(JSON.stringify(authChallenge));
      });
      this.ws.addEventListener('message', e => {
        const data = JSON.parse(e.data);
        console.log('- ws event:', data.event);

        const postsForChannel = store.getState().chat.chat;
        const channels = store.getState().chat.chats;
        const { page, perPage } = store.getState().chat;
        // on connect to socket success
        if (data.event === 'hello' && data.data.connection_id) {
          this.isConnectedToSocket = true;
        }
        // on posted
        if (data.event === 'posted') {
          // push new post to currently opened channel
          if (postsForChannel && postsForChannel.channelId === data.broadcast.channel_id) {
            const newPost = JSON.parse(data.data.post);
            const newPosts = { ...postsForChannel };
            newPosts.posts[newPost.id] = newPost;
            newPosts.order = [newPost.id, ...newPosts.order];
            store.dispatch(chatActions.setChat(newPosts));
          }

          // update last message data for loaded channels
          if (channels && channels.length > 0) {
            const channelOfNewPost = data.broadcast.channel_id;
            const channelsCopy = [...channels];
            const channelIndex = channelsCopy.findIndex(el => el.id === channelOfNewPost);
            if (channelIndex >= 0) {
              const newPostDate = JSON.parse(data.data.post).update_at;
              channelsCopy[channelIndex] = {
                ...channelsCopy[channelIndex],
                last_post_at: newPostDate,
              };
              store.dispatch(chatActions.setChats(channelsCopy));
            }
          }
          page === 1 && store.dispatch(chatActions.updateChats({ page, perPage }));
        }

        // on post deleted
        if (data.event === 'post_deleted') {
          if (postsForChannel && postsForChannel.channelId === data.broadcast.channel_id) {
            const postId = JSON.parse(data.data.post).id;
            const postsForChannelCopy = { ...postsForChannel };
            const idxInOrder = postsForChannelCopy.order.indexOf(postId);
            if (idxInOrder >= 0) {
              postsForChannelCopy.order.splice(idxInOrder, 1);
            }
            store.dispatch(chatActions.setChat(postsForChannelCopy));
          }
        }
      });
      this.ws.addEventListener('error', (e: any) => {
        console.log('- ws error:', e.message);
      });
      this.ws.addEventListener('close', (e: any) => {
        console.log('- we onclose:', `${e.code} ${e.message} ${e.reason}`);
        this.closeSocket();
      });
    } catch (e) {
      console.log('- ws error:', e.message);
    }
  }

  public static closeSocket() {
    console.log('close socket...', !!this.ws);
    this.ws?.close();
    this.ws?.removeEventListener('open', () => {});
    this.ws?.removeEventListener('message', () => {});
    this.ws?.removeEventListener('error', () => {});
    this.ws?.removeEventListener('close', () => {});
    this.ws = null;
    this.isConnectedToSocket = false;
  }

  public static clearToken() {
    this.myAuthToken = null;
  }
}
