import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { connect, ConnectedProps } from 'react-redux';
import { Box, Button, Card, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import clsx from 'clsx';
import { actions as productCatalogActions } from '../../../store/ducks/product-catalog.duck';
import { ImageGallery } from './components';
import { IAppState } from '../../../store/rootDuck';
import { setLayoutFooter, setLayoutSubheader } from '../../../utils/layout';
import { actions as productActions } from '../../../store/ducks/product.duck';
import Preloader from '../../../components/ui/Preloader/Preloader';
import { actions as cartTypesActions } from '../../../store/ducks/cart.duck';
import { actions as profileActions } from '../../../store/ducks/profile.duck';
import { IProduct } from '../../../interfaces/product';
import { thousands } from '../../../utils/utils';
import { useStylesProducView } from './hooks/useStylesProductView';
import { useDefineUserRole, useFormatMessage } from '../../../hooks';
import ProductIcons from '../../../components/ProductIcons';
import { toAbsoluteUrl } from '../../../../_metronic';
import { useFilterParams } from './hooks/useFilterParams';
import { MAP_KEY, WINE_TYPE } from '../../../constants';
import { GoogleMapComponent } from './components/GoogleMapComponent';
import NoWine from './components/NoWine';
import { useAxiosGetCallback } from '../../../hooks/useAxiosGet';
import { useGetSimilar } from './hooks/useGetSimilar';
import ProductsGrid from "../main/components/ProductsGrid";
import {useStylesProductCatalog} from "../main/hooks/useStyles";

const ProductView: React.FC<RouteComponentProps<{ id: string }> & TPropsFromRedux> = ({
  match: {
    params: { id },
  },
  product,
  loading,
  isAuthorized,
  guestCart,
  cart,
  me,
  loadingMe,
  addProductLoading,
  fetch,
  clearProduct,
  setProductCount,
  setProductCountLoading,
  countProductsGuest,
  companyGuestCart,
  fetchMe,
  clearMe,
  addProductToCart,
  clearById,
  byIdError,
  setViewType,
}) => {
  const intl = useIntl();
  const classes = useStylesProducView();
  const classesCatalog = useStylesProductCatalog();
  const history = useHistory();
  const boxRef: any = useRef();
  const backgroundRef: any = useRef();
  const degreeRef: any = useRef();
  const refInfo: any = useRef();
  const [y, setY] = useState();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [yBackground, setYBackground] = useState();
  const [heightBackground, setHeightBackground] = useState(0);
  const [yDegree, setYDegree] = useState(0);
  const [heightDegree, setHeightDegree] = useState(0);
  const [yInfo, setYInfo] = useState(0);
  const [countries, countriesLoading, countriesErr, makeRequest] = useAxiosGetCallback<any>();
  const [fetchSimilar, loadingSimilar, products] = useGetSimilar();
  const fm = useFormatMessage();
  const { enqueueSnackbar } = useSnackbar();
  const isBuyer = useDefineUserRole(me, 'ROLE_BUYER');
  const isAdmin = useDefineUserRole(me, 'ROLE_ADMIN');
  const [hideText, setHideText] = useState(false);
  const [width, setWidth] = useState<number>(window.innerWidth);
  const marginDesc = useMemo(() => {
    if (!y) {
      if (yBackground && heightBackground && heightDegree && yDegree) {
        if (yBackground + heightBackground > yDegree) {
          return heightBackground + 15;
        }
      }
    }
    return 0;
  }, [y, yBackground, heightBackground, heightDegree, yDegree]);

  const marginInfo = useMemo(() => {
    if (!y) {
      if (yBackground && heightBackground && yInfo) {
        const heightInfo = yInfo + 180;
        if (heightInfo > yBackground) {
          return heightInfo - yBackground + 10;
        }
      }
    }
    return 0;
  }, [y, yBackground, heightBackground, yInfo]);

  const paramsGroups = useFilterParams(product);

  const isWine = useMemo(() => product?.product_type?.id === WINE_TYPE, [product]);

  useEffect(() => {
    if (byIdError) {
      enqueueSnackbar(`${fm('ERROR')}: ${byIdError}`, {
        variant: 'error',
      });
      clearById();
      history.replace('/products/catalog');
    }
  }, [byIdError]);

  setLayoutSubheader({
    title: product?.category?.name || '',
    breadcrumb: [
      {
        title: fm('MENU.PRODUCTS.CATALOG'),
        root: true,
        page: 'products/catalog',
        translate: 'MENU.PRODUCTS.CATALOG',
      },
    ],
    back: true,
    show: true,
  });

  setLayoutFooter({ show: true });

  const updateWidth = () => setWidth(window.innerWidth);

  const getPosition = useCallback(() => {
    const y = boxRef?.current?.offsetTop;
    setY(y);
    if (backgroundRef?.current) {
      const yBackground = backgroundRef.current.offsetTop;
      const heightBackground = backgroundRef.current.clientHeight;
      setYBackground(yBackground);
      setHeightBackground(heightBackground);
    }
  }, [boxRef, backgroundRef]);

  const getBlocks = useCallback(() => {
    if (degreeRef?.current) {
      const heightDegreeNew = degreeRef.current.clientHeight;
      const yDegreeNew = degreeRef.current.offsetTop;
      !yDegree && setYDegree(yDegreeNew);
      !heightDegree && setHeightDegree(heightDegreeNew);
    }
    if (refInfo?.current) {
      const yInfoNew = refInfo.current.offsetTop;
      !yInfo && setYInfo(yInfoNew);
    }
  }, [degreeRef, refInfo]);

  useEffect(() => {
    boxRef && getPosition();
    backgroundRef && getPosition();
    getBlocks();
  }, [boxRef, paramsGroups, backgroundRef, degreeRef, product, refInfo]);
  useEffect(() => {
    fetchSimilar(id);
  }, [id]);
  useEffect(() => {
    fetch(Number(id));
    return () => {
      clearProduct();
      clearById();
    };
  }, [id]);

  useEffect(() => {
    makeRequest('/api/phone_codes');
    window.addEventListener('resize', updateWidth);
    window.addEventListener('resize', getPosition);
    getPosition();
    getBlocks();
    fetchMe();
    return () => {
      clearMe();
      window.removeEventListener('resize', getPosition);
    };
  }, []);

  // eslint-disable-next-line prefer-const
  let productCount = useMemo(() => {
    if (product && cart) {
      const item = cart.items.find(item => item.product.id === product.id);
      if (item) {
        return item.count;
      }
    }
    return 0;
  }, [isAuthorized, product, guestCart?.goods_num, cart?.goods_num, guestCart, cart]);

  const handleCartDialog = useCallback(
      (item: IProduct) => {
      addProductToCart({
        product_id: item.id ? item?.id : 0,
        count: 1,
      });
    },
    [addProductToCart]
  );
  const viewActionProduct = useCallback(
      (item: any, type?: string) => {
        history.push(`/viewproduct/${item.id}`, { state: { type } });
      },
      [history]
  );
  const handleSetCountCart = useCallback(
      (type: 'dec' | 'inc', count: number, product: IProduct) => {
        setProductCount({
          product_id: product.id || 0,
          count,
        });
      },
      [isAuthorized, countProductsGuest, companyGuestCart]
  );
  // const handleSetCountCart = useCallback(
  //     (count: number, product: IProduct) => {
  //       setProductCount({
  //         product_id: product.id || 0,
  //         count,
  //       });
  //     },
  //     [setProductCount]
  // );
  useEffect(() => {
    updateWidth();
  }, [updateWidth]);

  const cartHeight = useCallback(() => {
    if (width >= 900) {
      return '26vw';
    }
  }, [width]);

  const sortedData = useMemo(
    () =>
      paramsGroups[4]?.sort((a, b) => {
        const positionA = a.group_position || 0;
        const positionB = b.group_position || 0;

        return positionA - positionB;
      }),
    [paramsGroups]
  );

  if (loading || loadingMe || !product) return <Preloader />;

  if (!isWine || isSmallScreen)
    return (
      <NoWine
        me={me}
        product={product}
        getPosition={getPosition}
        isAuthorized={isAuthorized}
        productCount={productCount}
        setProductCountLoading={setProductCountLoading}
        handleSetCountCart={handleSetCountCart}
        addProductLoading={addProductLoading}
        handleCartDialog={handleCartDialog}
        countries={countries}
        loadingSimilar={loadingSimilar}
        products={products}
        isAdmin={isAdmin!!}
        cart={cart}
        guestCart={guestCart}
      />
    );

  return (
    <>
      <div className={classes.container}>
        <Card className={classes.card}>
          <div className={classes.header}>
            <p className={classes.name}>{product.name}</p>
          </div>
          <div
            className={classes.containerProduct}
            style={{
              minHeight: !y ? heightBackground + heightDegree + 350 : 700,
            }}
          >
            <img
              src={toAbsoluteUrl('/images/landscape.jpg')}
              alt='landscape'
              ref={backgroundRef}
              className={classes.imgBackground}
              style={{
                top: y ? y + 20 : 320,
              }}
              onLoad={() => getPosition()}
            />
            <div className={classes.body}>
              <div
                className={classes.leftCol}
                style={{
                  width: product?.photos && product.photos.length === 1 ? '20%' : '30%',
                }}
              >
                <ImageGallery product={product} />
              </div>
              <div
                className={classes.rightCol}
                style={{
                  width: product?.photos && product.photos.length === 1 ? '80%' : '70%',
                  justifyContent: marginDesc ? 'flex-end' : undefined,
                }}
              >
                <div
                  className={classes.info}
                  ref={refInfo}
                  style={{ marginBottom: marginInfo }}
                >
                  <div className={classes.wrapperProductInfo}>
                    <div className={classes.containerProductInfo}>
                      <div className={classes.infoFlex}>
                        <div className={classes.containerParams}>
                          <div className={classes.parameterValue}>
                            {paramsGroups[0] && paramsGroups[0].length > 0 && (
                              <div className={classes.parameter}>
                                {product.rating && (
                                  <Box className={classes.containerRate}>
                                    <img
                                      className={classes.rateIcon}
                                      src={toAbsoluteUrl('/media/icons/rate-icons.svg')}
                                      alt=''
                                      width={45}
                                      height={45}
                                    />
                                    <Typography className={classes.rating}>
                                      {product.rating}
                                    </Typography>
                                  </Box>
                                )}
                                {paramsGroups[0].map(param => (
                                  <div>
                                    <p className={classes.parameterText1}>
                                      {param.value || ''}
                                    </p>
                                    <p className={classes.parameterText2}>
                                      {param.parameter_name || ''}
                                    </p>
                                  </div>
                                ))}
                              </div>
                            )}
                            {paramsGroups[1] && paramsGroups[1].length > 0 && (
                              <div className={classes.icons}>
                                {paramsGroups[1].map(param => {
                                  if (param.parameter_name !== 'Marks') return;
                                  return (
                                    Array.isArray(param.value) &&
                                    param.value.map(key => <ProductIcons type={key} />)
                                  );
                                })}
                              </div>
                            )}
                          </div>
                          {paramsGroups[2] && paramsGroups[2].length > 0 && (
                            <div className={classes.infoBody}>
                              <div className={classes.infoBodyBlockFlex}>
                                {paramsGroups[2].map(
                                  (param, ind) =>
                                    ind < 3 && (
                                      <div className={classes.infoBlockText}>
                                        <p className={classes.infoText}>{param.value}</p>
                                        <p className={classes.infoText2}>
                                          {param.parameter_name}
                                        </p>
                                      </div>
                                    )
                                )}
                              </div>
                              <div className={classes.infoBodyBlockFlex}>
                                {paramsGroups[2].map(
                                  (param, ind) =>
                                    ind >= 3 && (
                                      <div className={classes.infoBlockText}>
                                        <p className={classes.infoText}>{param.value}</p>
                                        <p className={classes.infoText2}>
                                          {param.parameter_name}
                                        </p>
                                      </div>
                                    )
                                )}
                              </div>
                            </div>
                          )}
                        </div>
                        <div className={classes.price}>
                          <div className={classes.priceBody}>
                            {!!product.old_price && !!product.discount_price && (
                              <p className={classes.newPriceTitle}>
                                CZK {thousands(product.old_price.toString())}
                              </p>
                            )}
                            <p className={classes.priceTitle}>
                              CZK {thousands(product.price.toString())}
                            </p>
                            {(isBuyer || !isAuthorized) && productCount > 0 && (
                              <div className={classes.addFav}>
                                <button
                                  disabled={setProductCountLoading}
                                  onClick={() =>
                                    // eslint-disable-next-line no-plusplus
                                      handleSetCountCart('dec', --productCount!, product)
                                  }
                                  className={classes.favBtn}
                                  type='button'
                                >
                                  -
                                </button>
                                <p className={classes.favText}>{productCount}</p>
                                <button
                                  disabled={setProductCountLoading}
                                  onClick={() =>
                                    // eslint-disable-next-line no-plusplus
                                      handleSetCountCart('inc', ++productCount!, product)
                                  }
                                  className={classes.favBtn}
                                  type='button'
                                >
                                  +
                                </button>
                              </div>
                            )}
                            {(isBuyer || !isAuthorized) && (
                              <Button
                                disabled={
                                  addProductLoading
                                  // || !getStock(item) || (getStock(item) || 0) < 0
                                }
                                onClick={event => {
                                  if (productCount > 0) {
                                    event.stopPropagation();
                                    history.push('/cart');
                                  } else {
                                    event.stopPropagation();
                                    handleCartDialog(product);
                                  }
                                }}
                                color='primary'
                                className={classes.addBtn}
                              >
                                <div className={classes.containerAddProduct}>
                                  <img src={toAbsoluteUrl('/images/basket.svg')} alt='' />
                                  {productCount > 0
                                    ? fm('CART.SUBHEADER.TITLE')
                                    : fm('PRODUCT.BUTTON.ADD_CART')}
                                </div>
                              </Button>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className={classes.containerParamsTime}>
                        {paramsGroups[3] && paramsGroups[3].length > 0 && (
                          <div className={classes.time}>
                            {paramsGroups[3].map(param => (
                              <>
                                {param.parameter_name ===
                                  'Doporučená teplota vína pří podání, °C:' && (
                                  <img
                                    src={toAbsoluteUrl('/images/wine-time.svg')}
                                    alt=''
                                    height={38}
                                    width={38}
                                  />
                                )}
                                <div>
                                  <p className={classes.timeText1}>{param.value}</p>
                                  <p className={classes.timeText2}>{param.parameter_name}</p>
                                </div>
                              </>
                            ))}
                          </div>
                        )}
                      </div>
                    </div>
                    <div>
                      <div
                        style={{
                          minHeight: cartHeight(),
                        }}
                        ref={boxRef}
                      >
                        {paramsGroups[4] && paramsGroups[4].length > 0 && (
                          <div className={classes.description} ref={boxRef}>
                            {sortedData?.map(param => (
                              <div className={classes.descriptionBlock}>
                                <div className={classes.containerImgWine}>
                                  <img
                                    src={toAbsoluteUrl('/images/wine-bg.svg')}
                                    alt=''
                                    className={classes.imgPng}
                                  />
                                  <div className={classes.productParam}>
                                    {param.parameter_name}
                                  </div>
                                </div>
                                <p className={classes.descriptionText}>{param.value}</p>
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                {product.description && (
                  <div
                    className={classes.degree}
                    style={{ marginTop: marginDesc }}
                    ref={degreeRef}
                  >
                    <div className={classes.degreeBlock}>
                      <div
                        style={{
                          // eslint-disable-next-line no-nested-ternary
                          WebkitLineClamp: hideText ? 300 : window.innerWidth < 1200 ? 3 : 4,
                        }}
                        dangerouslySetInnerHTML={{ __html: product.description }}
                        className={classes.degreeText}
                      ></div>
                      <Typography
                        style={{ marginTop: !hideText ? '1rem' : 0, cursor: 'pointer' }}
                        onClick={() => setHideText(!hideText)}
                      >
                        {!hideText
                          ? intl.formatMessage({ id: 'PRODUCT.SHOW.ALL' })
                          : intl.formatMessage({ id: 'PRODUCT.HIDE' })}
                      </Typography>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className={classes.containerParamCompany}>
            <div className={classes.company}>
              {paramsGroups[5] && paramsGroups[5].length > 0 && (
                <div className={classes.companyBlock}>
                  {paramsGroups[5]
                    .filter(el =>
                      ['Vinařství ', 'Země původu / Country', 'Region (Itálie)'].includes(
                        el?.parameter_name || ''
                      )
                    )
                    .map(param => (
                      <div>
                        <p className={classes.companyText1}>
                          {param.parameter_type === 'country'
                            ? countries?.find((el: any) => el.ru_name === param.value)
                                ?.en_name ||
                              param.value?.toString() ||
                              ''
                            : param.value}
                        </p>
                        <p className={classes.companyText2}>{param.parameter_name}</p>
                      </div>
                    ))}
                </div>
              )}
              <img
                className={classes.searchOnMap}
                src={toAbsoluteUrl('/media/icons/searchonmap.png')}
                alt=''
                onClick={() => setViewType('map')}
              />
            </div>
            <div className={classes.infoCompany}></div>
            <div className={classes.mapBlock}>
              <GoogleMapComponent
                product={product}
                isMarkerShown
                googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${MAP_KEY}&v=3.exp&libraries=geometry,drawing,places&language=en`}
                loadingElement={<div className={classes.element} />}
                containerElement={<div className={classes.element} />}
                mapElement={<div className={classes.element} />}
              />
            </div>
          </div>
           {loadingSimilar ? (
              <Preloader />
           ) : (
              <>
                {!products || !products.length ? (
                    <></>
                ) : (
                    <div
                        className={clsx(classesCatalog.card, {
                          [classesCatalog.card1elm]: true,
                        })}
                    >
                      <ProductsGrid
                          addProductLoading={addProductLoading}
                          cart={cart}
                          guestCart={guestCart}
                          setProductCountLoading={setProductCountLoading}
                          handleCartDialog={handleCartDialog}
                          handleSetCountCart={handleSetCountCart}
                          isAuthorized={isAuthorized}
                          isAdmin={isAdmin!!}
                          me={me}
                          viewAction={viewActionProduct}
                          data={products}
                      />
                    </div>
                )}
              </>
           )}
        </Card>
      </div>
    </>
  );
};

const connector = connect(
  (state: IAppState) => ({
    product: state.products.product,
    loading: state.products.byIdLoading,
    isAuthorized: state.auth.user != null,
    guestCart: state.cart.guestCart,
    cart: state.cart.cart,
    setProductCountLoading: state.cart.setProductCountLoading,
    countProductsGuest: state.cart.guestCart?.items.length,
    companyGuestCart: state.cart.guestCart?.cartCompanyId,
    me: state.profile.me,
    loadingMe: state.profile.loading,
    addProductLoading: state.cart.addProductLoading,
    byIdError: state.products.byIdError,
  }),
  {
    fetch: productActions.fetchByIdRequest,
    clearById: productActions.clearById,
    clearProduct: productActions.clearProduct,
    setProductCount: cartTypesActions.setProductCountRequest,
    fetchMe: profileActions.fetchRequest,
    clearMe: profileActions.clearMe,
    addProductToCart: cartTypesActions.addProductRequest,
    setViewType: productCatalogActions.setViewType,
  }
);

type TPropsFromRedux = ConnectedProps<typeof connector>;

export default connector(ProductView);
