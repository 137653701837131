import { useFormik } from 'formik';
import { useIntl } from 'react-intl';
import * as Yup from 'yup';

export const useFormikForgotPassword = (makeRequest: any) => {
  const intl = useIntl();

  return useFormik({
    initialValues: { email: '' },
    validationSchema: Yup.object().shape({
      email: Yup.string()
        .email(intl.formatMessage({ id: 'AUTH.INPUT.EMAIL_INCORRECT' }))
        .required(intl.formatMessage({ id: 'AUTH.INPUT.EMAIL_REQUIRED' })),
    }),
    onSubmit: submitValues => {
      makeRequest(`/api/_p/reset_password/send_code?email=${submitValues.email}`);
    },
  });
};
