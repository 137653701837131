import React from 'react'
import {connect} from 'react-redux'
import objectPath from 'object-path'
import * as builder from '../../ducks/builder'
import KTToggle from '../../_assets/js/toggle'
import UserProfile from '../../partials/layout/UserProfile'
import HeaderMobileNavigation from "../../../app/components/ui/HeaderMobileNavigation";
import {UserRoles} from "../../../app/interfaces/user";
import {Link} from "react-router-dom";
import {toAbsoluteUrl} from "../../utils/utils";
import SearchProducts from "./SearchProducts";
import {actions as productActions} from "../../../app/store/ducks/product.duck";

class HeaderMobile extends React.Component {
  toggleButtonRef = React.createRef();

  componentDidMount() {
    new KTToggle(this.toggleButtonRef.current, this.props.toggleOptions);
  }

  render() {
    const {
      headerMobileCssClasses,
      headerMobileAttributes,
      headerMenuSelfDisplay,
      me,
      setFocusSearch,
      focus
    } = this.props;

    return (
      <div id='kt_header_mobile'>
        <div
          className={`kt-header-mobile ${headerMobileCssClasses}`}
          {...headerMobileAttributes}
        >
          <div
            className='kt-header-mobile__toolbar'
            style={{
              flex: 0.4,
              display: me?.roles.includes(UserRoles.ROLE_BUYER) || !me ? 'none' : 'flex',
            }}
          >
            {headerMenuSelfDisplay && (
              <button className='kt-header-mobile__toggler' id='kt_header_mobile_toggler'>
                <span/>
              </button>
            )}
          </div>
          <div className='kt-header-logo'>
            <Link to={`/main/catalog`}>
              {/*<p style={{ margin: 0, fontWeight: '200', fontSize: 44, color: '#441861' }}>*/}
              {/*  Barelton*/}
              {/*</p>*/}
              <img
                alt='logo'
                src={toAbsoluteUrl(`/media/logos/logo-barelton-sm.svg`)}
                height={32}
                width={180}
              />
              {/*  <img alt='logo' src={this.getHeaderLogo()} />*/}
            </Link>
          </div>
          <div className='kt-header-mobile__toolbar' style={{justifyContent: 'flex-end'}}>
            <UserProfile showAvatar={true} showHi={true} showBadge={false}/>
          </div>
        </div>
        <div className={'kt-header-mobile-search'}>
          <SearchProducts/>
          {focus && (
            <div
              onClick={() => setFocusSearch(false)}
              style={{
                position: 'absolute',
                top: '100%',
                zIndex: 10,
                bottom: 0,
                left: 0,
                right: 0,
                height: '100vh',
                background: 'rgba(0,0,0,0.5)',
              }}/>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = store => ({
  me: store.profile.me,
  focus: store.products.searchFocus,
  headerLogo: builder.selectors.getStickyLogo(store),
  asideDisplay: objectPath.get(store.builder.layoutConfig, 'aside.self.display'),
  headerMenuSelfDisplay:
    objectPath.get(store.builder.layoutConfig, 'header.menu.self.display') === true,
  toggleOptions: {
    target: 'body',
    targetState: 'kt-header__topbar--mobile-on',
    togglerState: 'kt-header-mobile__toolbar-topbar-toggler--active',
  },
  headerMobileCssClasses: builder.selectors.getClasses(store, {
    path: 'header_mobile',
    toString: true,
  }),
  headerMobileAttributes: builder.selectors.getAttributes(store, {
    path: 'aside_menu',
  }),
});

export default connect(
  mapStateToProps,
  {
    setFocusSearch: productActions.setFocusSearch,
  }
)(HeaderMobile);
