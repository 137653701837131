import axios from 'axios';
import { GET_TRANSACTIONS_URL } from '../constants';
import { IFilteredData } from '../interfaces/promocodes';

export function getTransactions(
  page: number,
  perPage: number,
  isFilter: boolean = false,
  filterData?: IFilteredData,
  user_id?: string
) {
  if (isFilter) {
    return axios.get(
      `${GET_TRANSACTIONS_URL}?page=${page}&per_page=${perPage}&start_date=${filterData?.start_date}&end_date=${filterData?.end_date}`
    )
  }
  if (user_id) {
    return axios.get(
      `${GET_TRANSACTIONS_URL}?page=${page}&per_page=${perPage}&user_id=${user_id}`
    );
  }
  return axios.get(
    `${GET_TRANSACTIONS_URL}?page=${page}&per_page=${perPage}`
  );

}
