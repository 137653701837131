import axios from 'axios';
import { getCreateMessageUrl, getMessagesUrl } from '../constants';
import { IMessageCreate } from '../interfaces/order';

export const getMessages = ({
  page,
  perPage,
  order_id,
}: {
  page: number;
  perPage: number;
  order_id: number;
}) => {
  return axios.get(getMessagesUrl(order_id, page, perPage));
};

export const createMessage = (data: IMessageCreate) => {
  const formData = new FormData();

  formData.append('for_user_id', data.for_user_id.toString());
  formData.append('order_id', data.order_id.toString());
  formData.append('text', data.text.toString());

  return axios.post(getCreateMessageUrl(), formData);
};
