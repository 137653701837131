import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import {
  Button,
  CardActions,
  Card,
  CardContent,
  CardMedia,
  Theme,
  Typography,
  makeStyles,
  createStyles
} from '@material-ui/core';
// import { makeStyles, createStyles } from '@material-ui/core';
import Slider from 'react-slick';
import {ArrowBackIos,ArrowForwardIos} from "@material-ui/icons";

import { API_DOMAIN } from '../../../../constants';
import { formatAsThousands } from '../../../../utils/utils';

import { IProduct } from '../../../../interfaces/product';
import { useDefineUserRole, useFormatMessage } from '../../../../hooks';
import { IUser, UserRoles } from '../../../../interfaces/user';
import StarActive from './StarActive';
import StarDisable from './StarDisable';
import {toAbsoluteUrl} from "../../../../../_metronic";
import {CustomArrow} from "../../../../components/ui/Slider/ArrowButton";
import ProductsGridItem from "../../products/components/ProductGrid/ProductGridItem";
import {ICart, IGuestCart} from "../../cart/interfaces";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'grid',
      gridGap: 24,
      gridTemplateColumns: 'repeat(4, 220px)',

      [theme.breakpoints.down('sm')]: {
        gridTemplateColumns: 'repeat(2, 1fr)',
      },
      [theme.breakpoints.up('sm')]: {
        gridTemplateColumns: 'repeat(2, 1fr)',
      },
      [theme.breakpoints.up('md')]: {
        gridTemplateColumns: 'repeat(3, 1fr)',
      },
      [theme.breakpoints.up('lg')]: {
        gridTemplateColumns: 'repeat(3, 1fr)',
      },
      [theme.breakpoints.up('xl')]: {
        gridTemplateColumns: 'repeat(4, 1fr)',
      },
      // @ts-ignore
      [theme.breakpoints.up('xxl')]: {
        gridTemplateColumns: 'repeat(5, 1fr)',
      },
    },
    productCard: {
      maxWidth: 285,
      cursor: 'pointer',
      transition: '.3s ease-out',
      boxShadow: 'none !important',
      margin: '0 auto',
      '&:hover': {
        transform: 'scale(1.01)',
      },
      minHeight: 352,
      // [theme.breakpoints.down(650)]: {
      //   margin: '0 auto !important',
      //   // width: 232,
      // },
    },
    containerBtns: {
      display: 'flex',
      flexDirection: 'row',
      [theme.breakpoints.down('md')]: {
        flexWrap: 'wrap',
      },
    },
    btnSupport: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      height: 45,
      [theme.breakpoints.down('md')]: {
        height: 30,
      },
    },
    btnAddProduct: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      height: 45,
      [theme.breakpoints.down('md')]: {
        // marginBottom: 10,
      },
    },
    cardContent: {
      padding: '15px 15px 0 15px',
      // height: 130,
      overflow: 'hidden',
      [theme.breakpoints.down('md')]: {
        height: 68,
      },
    },
    productName: {
      height: 50,
      display: '-webkit-box',
      '-webkit-box-orient': 'vertical',
      '-webkit-line-clamp': 3,
    },
    columnContent: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    reviewContent: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    name: {
      marginBottom: 3,
      lineHeight: 1.3,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: '-webkit-box',
      WebkitLineClamp: 2,
      WebkitBoxOrient: 'vertical',
    },
    sliderContainer: {
        display: 'flex',
        maxWidth: 1440,
        margin: '0 auto',
        alignItems: 'flex-start',
        position: 'relative',
        [theme.breakpoints.down(880)]: {
           // alignItems: 'flex-start',
            margin: '0 auto',
            marginTop: 160,
        },
    },
    fixedControls: {
      position: 'absolute',
      zIndex: 1000,
      backgroundColor:'#fff',
      display: 'flex',
      justifyContent: 'space-between',
      flexDirection:'column',
      left: 0,
      right: 0,
        padding:15,
        width:300,
        height:450,
        [theme.breakpoints.down(880)]: {
            width: '100%',
            maxHeight:145,
            top: '-150px',
           // marginLeft:0,
        },
        [theme.breakpoints.down(460)]: {
            maxHeight:180,
            top: '-185px',
            // marginLeft:0,
        },
    //  top: '-50px', // Чтобы блок был над слайдером
    },
    sliderWrapper: {
      // width: '100%',
      width: 'calc(100% - 315px)',
      marginLeft:300,
    //  marginLeft: '100px', // Для отступа от кнопок слева
        [theme.breakpoints.down(880)]: {
            width: '100%',
            marginLeft:0,
        },
    },
  })
);

interface IProductsProps {
  data: IProduct[];
  page?: number;
  perPage?: number;
  total?: number;
  categoryId?: string;
  companyId?: string;
  fetch?: (data: { page: number; perPage: number }) => void;
  viewAction: any;
  editAction?: any;
  handleDeleteDialog?: any;
  isAuthorized: boolean;
  isAdmin: boolean;
  me: IUser | undefined;
  addProductLoading: boolean;
 // handleCartDialog?: any;
  isPaginator?: boolean;
  edit?: any;
  isHideProduct?: boolean;
  isSearching?: boolean;
    cart?: ICart | null;
    guestCart?: IGuestCart | null;
    setProductCountLoading?: boolean;
    handleSetCountCart?: (type: 'dec' | 'inc', count: number, product: IProduct) => void;
    handleCartDialog?:(item:IProduct) => void;
}

const ProductsGrid: FC<IProductsProps> = (props) => {

  const {data,
        viewAction,
        editAction,
        handleDeleteDialog,
        isAdmin,
        me,
        addProductLoading,
        handleCartDialog,
      setProductCountLoading,
      handleSetCountCart,
       cart,
      guestCart,
        edit,
        isHideProduct,
         }= props;
  const fm = useFormatMessage();
  const classes = useStyles();
  const ref = useRef(null);
  const sliderRef = useRef<any>(null);
  const [heightImg, setHeightImg] = useState(285);
  const isBuyer = useDefineUserRole(me, 'ROLE_BUYER');
  const getStock = useCallback(
    (product: IProduct) => {
      return isBuyer || !me ? product.stock_info?.available_quantity : product.stock_quantity;
    },
    [isBuyer, me]
  );

  useEffect(() => {
    const rootObserver = new ResizeObserver((entries: ResizeObserverEntry[]) => {
      const len = entries[0].target.clientWidth / 3;
      setHeightImg(entries[0].target.clientWidth + len);
    });
    ref.current && rootObserver.observe(ref?.current);

    return () => {
      rootObserver.disconnect();
    };
  }, []);

  const next = () => {
    if (sliderRef.current) {
      if (sliderRef.current.slickNext) {
        sliderRef.current.slickNext();
      }
    }
  };

  const previous = () => {
    if (sliderRef.current && sliderRef.current.slickPrev) {
      sliderRef.current.slickPrev();
    }
  };

  const changeHide = useCallback((item: IProduct) => {
    if (isHideProduct) {
      const data = new FormData();
      data.append('active', '0');
      edit({ id: Number(item?.id), data });
    }
  }, []);

  const settings = {
    dots: false,
    infinite: data.length > 2,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    // prevArrow: <CustomArrow type='left' />,
   // nextArrow: <CustomArrow type='right' />,
    responsive: [
      // {
      //   breakpoint: 1500,
      //   settings: {
      //     centerMode: false,
      //     centerPadding: '20px',
      //     slidesToShow: 3,
      //   },
      // },
      {
        breakpoint: 1300,
        settings: {
          centerMode: false,
          centerPadding: '20px',
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 1000,
        settings: {
          centerMode: false,
          centerPadding: '20px',
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 650,
        settings: {
          centerMode: false,
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 350,
        settings: {
          centerMode: false,
          slidesToShow: 1,
        },
      },
    ],
  };

  const productSize = data.length < 4 ? 'larger' : 'normal';

  return (
    <div className={classes.sliderContainer}>
      <div className={classes.fixedControls}
      >
          <Typography style={{fontSize:34,fontWeight:300}}>
              {fm('PRODUCT.SIMILAR')}
          </Typography>
          <div style={{display:'flex',justifyContent:'space-between'}}>
          <button
              type="button"
              onClick={previous}
              className='kt-subheader__btn-back'
              disabled={data.length < 3}
          >
              <ArrowBackIos/>
          </button>
          <button
              type="button"
              onClick={next}
              className='kt-subheader__btn-back'
              disabled={data.length < 3}
          >
              <ArrowForwardIos style={{marginLeft: 7}}/>
          </button>
          </div>
      </div>
      <div className={classes.sliderWrapper}>
      <Slider {...settings} ref={sliderRef}>
        {data.map((item, index) => {
          return <ProductsGridItem productSize={productSize} isSlider  index={index} key={item.id} item={item}  {...props} />;
        })}
      </Slider>
       </div>
    </div>
  );
};

export default ProductsGrid;
